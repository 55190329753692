<template>
  <section>
    <div class="update-header">
        There's been a few changes! - <a @click="store.navigate('/whats-new')">Click here to see what's up</a>
    </div>
    <h3 class="track-title-main">
      Here you can relive your fondest Disney memories, by requesting the audio
      tracks from the attractions, resorts, and parades that you have come to
      cherish in the past and present.
    </h3>
    <div id="home">
      <HomeStation :station="i" v-for="(i, idx) in store.stations" :key="idx" />
    </div>
  </section>
</template>

<script>
import HomeStation from "./HomeStation.vue";
import { store } from "@/store";

export default {
  components: {
    HomeStation,
  },
  data() {
    return {
      store: store,
    };
  },
};
</script>


<style scoped lang="stylus">
h3
  text-align center
  padding 10px
  color rgba(255, 255, 255, 0.8)

.update-header
  text-align center
  max-width 600px
  padding 13px 0 10px 0
  font-size 130%
  font-variant small-caps
  font-weight 500
  color rgba(255, 255, 255, 0.8)
  background-color rgba(126, 38, 141, 0.25)
  margin 0 auto
  padding 10px 15px
  border-radius 10px
  border 1px solid #44154D80
  box-shadow 3px 3px 4px #00000080
  text-shadow 3px 3px 4px #00000080

#home
  display flex
  flex-wrap wrap

  > div
    flex 1 0 48%
</style>
