<template lang="pug">
  i.fa-star.hover(
    @click.stop="handleFavorite()"
    :class="isFavorite ? 'fa-solid' : 'fa-regular darken'"
  )
</template>

<script>
import { store } from "@/store";
import { ApiCalls } from "@/components/utils/api-calls";
import SrUtils from "@/mixins/srutils.js";

export default {
  mixins: [SrUtils],
  inject: ['showLogin'],
  props: {
    track_id: Number,
  },
  data() {
    return {};
  },
  computed: {
    isFavorite() {
      return store.state.user.favorites.includes(this.track_id)
    },
  },
  methods: {
    async handleFavorite() {
      // Check if the user is logged in
      const isValid = await this.$auth.isValidAuth();
      if (!isValid) {
        this.showLogin("Please Login to Modify Favorite");
        return;
      }
      
      const { user_id } = this.$auth.getPayload() || {};
      if(!this.isFavorite) {
        ApiCalls.user.AddFavorite(user_id,this.track_id);
        // Add track id to stored list
        store.state.user.favorites.push(this.track_id)
      } else {
        ApiCalls.user.RemoveFavorite(user_id, this.track_id);
        // Remove track id from stored list
        store.state.user.favorites.splice(
          store.state.user.favorites.indexOf(this.track_id), 1
        );
      }
    },

  },
};
</script>