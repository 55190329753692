<template>
    <div class="group">
        <div class="login-part" id="left">
            <div class="title">Login</div>
            <div class="api-error" v-if="apiError" :class="apiError ? 'invalid' : ''">
                {{ this.apiError }}
            </div>
            <form>
                <validated-field :validations="$v.credentials.user" v-model="credentials.user" autocomplete="section-login username" fieldname="Username" fieldid="n-username" fieldtype="text" />
                <validated-field :validations="$v.credentials.password" v-model="credentials.password" autocomplete="section-login current-password" fieldname="Password" fieldid="n-password" fieldtype="password" />
            </form>
            <div class="field-group">
                <a @click="navigate({ name: 'ForgotPassword' })">I Forgot My Password!</a>
            </div>
            <div class="button-group col">
                <button class="large-btn signin-btn" @click="authLocal">Sign In</button>
                <!-- <button class="large-btn discord-btn" @click="authRemote('discord')">
              log in with
              <span>Discord</span>
            </button>
            <button class="large-btn google-btn" @click="authRemote('google')">
              log in with
              <span>Google</span>
        </button>-->
            </div>
        </div>
        <!-- <div class="login-part" id="right"></div> -->
    </div>
</template>

<script>
import { store } from "@/store";
import { required } from "vuelidate/lib/validators";
import ValidatedField from "@/components/validation/validated-field";

import { TokenValidation } from "@/components/auth/tokenvalidation";

export default {
  name: "login",
  inject: ["hideDialog", "showWait"],
  data() {
    return {
      credentials: {
        user: "",
        password: "",
      },
      apiError: null,
    };
  },
  components: {
    ValidatedField,
  },
  created() { },
  validations: {
    credentials: {
      user: { required },
      password: { required },
    },
  },
  watch: {},
  computed: {},
  mounted() { },

  methods: {
    navigate(page) {
      store.navigate(page);
    },
    authLocal: function () {
      this.apiError = null;
      this.$v.credentials.$touch();

      if (this.$v.credentials.$error) return; //If not validated stop

      if (this.$auth.isAuthenticated()) {
        this.$auth.forcedLogout();
      }
      this.showWait();
      this.$auth
        .login({
          user: this.credentials.user,
          password: this.credentials.password,
        })
        .then(this.performAfterLogin.bind(this));
    },

    authRemote: function (provider) {
      this.apiError = null;
      if (this.$auth.isAuthenticated()) {
        this.$auth.forcedLogout();
      }

      this.$auth
        .authenticate(provider)
        .then(this.performAfterLogin.bind(this))
        .catch(function (e) {
          // Something went wrong.
          // eslint-disable-next-line
          console.error(e);
        });
    },

    performAfterLogin: function (response) {
      if (response.data.error) {
        this.hideDialog();
        this.apiError = response.data.error;
      } else {
        TokenValidation.validateToken(response.data.token)
          .then(() => {
            //store.navigate((this.$auth.AfterLoginRoute) ? this.$auth.AfterLoginRoute.path : "/");
            this.$auth.RedirectAfterLogin("/");
            this.hideDialog();
          })
          .catch(() => {
            this.hideDialog();
          });
          this.$userInit();
      }
    },
  },
};
</script>

// <style lang="stylus" src="@/assets/stylus/auth-styling.styl"/>
