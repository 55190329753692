<template lang="pug">
  .pagination-with-page-numbers
    i.fa-solid.fa-caret-left(
      @click="currentPage != 1 && changePage(currentPage - 1)"
      :class="{ disabled: currentPage === 1 }"
    )
    //i(v-for="page in totalPages" :key="page" @click="changePage(page)" :class="{ active: currentPage === page }") {{ page }}

    //i(v-for="pageNumber in displayedPages", :key="pageNumber", @click="changePage(pageNumber)", :class="{ active: currentPage === pageNumber }") {{ pageNumber }}
    i(
      v-for="pageNumber in displayedPages"
      :key="pageNumber",
      @click="pageNumber !== null && changePage(pageNumber)"
      :class="{ active: currentPage === pageNumber, disabled: pageNumber === null }"
    ) {{ pageNumber === null ? '...' : pageNumber }}

    i.fa-solid.fa-caret-right(
      @click="currentPage != totalPages && changePage(currentPage + 1)"
      :class="{ disabled: currentPage === totalPages }"
    )
</template>

<script>
export default {
  props: {
    totalItems: Number,
    itemsPerPage: Number,
    currentPage: Number
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    displayedPages() {
      if (this.totalPages <= 10) {
        return Array.from({ length: this.totalPages }, (_, i) => i + 1);
      } else {
        if (this.currentPage <= 5) {
          return Array.from({ length: 7 }, (_, i) => i + 1).concat([null, this.totalPages]);
        } else if (this.currentPage >= this.totalPages - 4) {
          return [1, null].concat(Array.from({ length: 7 }, (_, i) => this.totalPages - 6 + i));
        } else {
          return [1, null].concat(Array.from({ length: 5 }, (_, i) => this.currentPage - 2 + i)).concat([null, this.totalPages]);
        }
      }
    }
  },
  methods: {
    changePage(pageNumber) {
      this.$emit('page-changed', pageNumber);
    }
  }
};
</script>

<style scoped lang="stylus">
.pagination-with-page-numbers
    text-align center
    i
        display inline-block
        width 30px
        height 20px
        margin 2px
        background radial-gradient(at top left, #FFF, #666)
        border-radius 5px
        font-size 15px
        line-height 20px
        text-align center
        font-style normal
        color #fff
        font-weight bold
        text-shadow #000 -1px -1px 1px
        cursor pointer
        transition all 0.25s ease-in-out
        user-select none /* prevent text selection */
        &:hover
          box-shadow #fff 0 0 5px
          background radial-gradient(at top left, #FFF, #aaa)
        &.active
          box-shadow #66f 0 0 5px, #fff 0 0 7px
          background radial-gradient(at top left, #FFF, #00F)
          color #ddf
        &.disabled
          box-shadow none
          background radial-gradient(at top left, #888, #444)
          color #999
          cursor not-allowed

</style>
