export const PolicyText = {
    terms: `
    <p>By accessing “Subsonic Radio” (hereinafter “we”, “us”, “our”, “Subsonic Radio”, “http://www.subsonicradio.com/forum”), you agree to be legally bound by the following terms. If you do not agree to be legally bound by all of the following terms then please do not access and/or use “Subsonic Radio”. We may change these at any time and we’ll do our utmost in informing you, though it would be prudent to review this regularly yourself as your continued usage of “Subsonic Radio” after changes mean you agree to be legally bound by these terms as they are updated and/or amended.</p>
    <p>Our forums are powered by phpBB (hereinafter “they”, “them”, “their”, “phpBB software”, “www.phpbb.com”, “phpBB Limited”, “phpBB Teams”) which is a bulletin board solution released under the “GNU General Public License v2” (hereinafter “GPL”) and can be downloaded from www.phpbb.com. The phpBB software only facilitates internet based discussions; phpBB Limited is not responsible for what we allow and/or disallow as permissible content and/or conduct. For further information about phpBB, please see: https://www.phpbb.com/.</p>
    <p>You agree not to post any abusive, obscene, vulgar, slanderous, hateful, threatening, sexually-orientated or any other material that may violate any laws be it of your country, the country where “Subsonic Radio” is hosted or International Law. Doing so may lead to you being immediately and permanently banned, with notification of your Internet Service Provider if deemed required by us. The IP address of all posts are recorded to aid in enforcing these conditions. You agree that “Subsonic Radio” have the right to remove, edit, move or close any topic at any time should we see fit. As a user you agree to any information you have entered to being stored in a database. While this information will not be disclosed to any third party without your consent, neither “Subsonic Radio” nor phpBB shall be held responsible for any hacking attempt that may lead to the data being compromised.</p>
    `,
    privacy: `
    <p>This policy explains in detail how “Subsonic Radio” along with its affiliated companies (hereinafter “we”, “us”, “our”, “Subsonic Radio”, “http://www.subsonicradio.com/forum”) and phpBB (hereinafter “they”, “them”, “their”, “phpBB software”, “www.phpbb.com”, “phpBB Limited”, “phpBB Teams”) use any information collected during any session of usage by you (hereinafter “your information”).</p>
    <p>Your information is collected via two ways. Firstly, by browsing “Subsonic Radio” will cause the phpBB software to create a number of cookies, which are small text files that are downloaded on to your computer’s web browser temporary files. The first two cookies just contain a user identifier (hereinafter “user-id”) and an anonymous session identifier (hereinafter “session-id”), automatically assigned to you by the phpBB software. A third cookie will be created once you have browsed topics within “Subsonic Radio” and is used to store which topics have been read, thereby improving your user experience.</p>
    <p>We may also create cookies external to the phpBB software whilst browsing “Subsonic Radio”, though these are outside the scope of this document which is intended to only cover the pages created by the phpBB software. The second way in which we collect your information is by what you submit to us. This can be, and is not limited to: posting as an anonymous user (hereinafter “anonymous posts”), registering on “Subsonic Radio” (hereinafter “your account”) and posts submitted by you after registration and whilst logged in (hereinafter “your posts”).</p>
    <p>Your account will at a bare minimum contain a uniquely identifiable name (hereinafter “your user name”), a personal password used for logging into your account (hereinafter “your password”) and a personal, valid email address (hereinafter “your email”). Your information for your account at “Subsonic Radio” is protected by data-protection laws applicable in the country that hosts us. Any information beyond your user name, your password, and your email address required by “Subsonic Radio” during the registration process is either mandatory or optional, at the discretion of “Subsonic Radio”. In all cases, you have the option of what information in your account is publicly displayed. Furthermore, within your account, you have the option to opt-in or opt-out of automatically generated emails from the phpBB software.</p>
    <p>Your password is ciphered (a one-way hash) so that it is secure. However, it is recommended that you do not reuse the same password across a number of different websites. Your password is the means of accessing your account at “Subsonic Radio”, so please guard it carefully and under no circumstance will anyone affiliated with “Subsonic Radio”, phpBB or another 3rd party, legitimately ask you for your password. Should you forget your password for your account, you can use the “I forgot my password” feature provided by the phpBB software. This process will ask you to submit your user name and your email, then the phpBB software will generate a new password to reclaim your account.</p>
    `,
};

