import Vue from "vue"
import { store } from "@/store";

Vue.filter("first4Chars", str => str.substring(0, 4))
Vue.filter("last4Chars", str => str.substring(str.length - 4))
Vue.filter("trackTitleFilter", str => {
  const use = localStorage.getItem("useTrackTitleFilter") === 'true';
  if(!use) { return str; }

  const replaceMap = {
    'Walt Disney World': 'WDW',
      'Animal Kingdom': 'AK',
      'Magic Kingdom': 'MK',
        'Tomorrowland Transit Authority': 'TTA',
    'Disneyland Resort': 'DLR',
      'Disneyland': 'DL',
      'California Adventure': 'DCA',
  };
  // Create a regular expression that matches all keys in the searchAndReplaceObj
  const searchKeys = Object.keys(replaceMap).map(key => key.replace(/[.*+?^${}()|[\]\\]/ig, '\\$&')).join('|');
  const regex = new RegExp(searchKeys, 'g');
    
  // Replace all occurrences of the keys in the input string with their corresponding replacement values
  return str.replace(regex, matched => replaceMap[matched]);
})