import { store, URLS } from "@/store";
import axios from "axios";

//import Progress from "@/components/ui/Progress-Simple.vue";
import Progress from "@/components/ui/Progress-Waveform.vue";
import { setTimeout } from "timers";
import Times from "@/mixins/mixins.js";
import Mediasession from "@/mixins/mediasession.js";
import SrUtils from "@/mixins/srutils.js";
const DEFAULT_IMAGE = "/icons/placeholder_icon.png";

export default {
    mixins: [Times, Mediasession, SrUtils ],
    components: {
        Progress
    },
    data() {
        return {
            STATE: store.state,
            p: false, // The player object
            audioCtx: false, // For the visualizer
            isPlaying: false,
            isLoading: false,
            percentProgress: 0,
            volume: 50,
            track: false,
            track_tmp: false, // Because of two JSON requests.  Once it's one, we'll remove
            remain: ""
        };
    },
    computed: {
        playerPlay() {
            return store.state.player;
        }
    },
    watch: {
        // Watch for changes in state.player from computed above
        playerPlay() {
            this.play();
        }
    },
    mounted() {
        this.p = this.$refs.audio_player; // Save the audio object
        this.vis = this.$refs.visualizer;
        this.volume = localStorage.getItem('playerVolume') ?? this.volume;
        window.addEventListener("resize", this.handleResize, { passive: true });

        Mediasession.methods.setActionHandlers({
            handlerPlay: this.play, 
            handlerPause: this.pause});
    },
    methods: {
        togglePlayer() {
            this.isPlaying = this.p.paused ? true : false;
            this.p.paused ? this.play() : this.pause();
        },
        pause() {
            Mediasession.methods.setPlaybackStatePaused();
            this.p.pause();
            this.STATE.playerCurTrackBgImage = '';
            cancelAnimationFrame(this.visLoopRequest);
        },
        play() {
            Mediasession.methods.setPlaybackStatePlaying();
            Mediasession.methods.setMetadata({
                station: this.STATE.player.station_name,
                image: this.getStreamIcon()
            });

            this.p.src = `${URLS.Stream}/${this.STATE.player.station_slug}.mp3`;
            },
        loadstart() {
            //console.log("loadstart");
            this.p.play();
            this.getCurrentTrack();
            this.isPlaying = false;
            this.isLoading = true;
            this.track = false;
        },
        canplay() {
            //console.log("canplay");
            if (!this.play) return; // Don't play immediately
            this.changeVolume(this.volume);
            this.p.play();
            this.isPlaying = true;
            this.isLoading = false;
        
            Mediasession.methods.setMetadata({
                trackName: this.track.name,
                station: this.STATE.player.name,
                image: this.getStreamIcon()
            });

            if (!this.audioCtx) {
                // Only attach audioCtx once
                this.audioCtx = new (window.AudioContext ||
                    window.webkitAudioContext)();
                this.analyser = this.audioCtx.createAnalyser();
                var src = this.audioCtx.createMediaElementSource(this.p);
                src.connect(this.analyser);
                this.analyser.connect(this.audioCtx.destination);
                this.canvasCtx = this.vis.getContext("2d"); // Get the canvas container
            }
            this.handleResize();
            this.visualization();
        },
        changeVolume() {
            localStorage.setItem('playerVolume', this.volume);
            this.p.volume = this.volume / 100;
        },
        handleResize() {
            this.vis.width = 0; // Set to 0 first so it doesn't mess up the container
            this.visWidth = Math.floor(this.$refs.infoContainer.offsetWidth);
            this.vis.width = this.visWidth;
        },
        visualization() {
            //console.log("BE", this.p.buffered.end(0), "CT", this.p.currentTime);
            //console.log(this.p.buffered);
            this.analyser.fftSize = 1024;
            var bufferLength = this.analyser.frequencyBinCount;
            var dataArray = new Uint8Array(bufferLength);
            this.analyser.getByteFrequencyData(dataArray);
            //console.log(dataArray);
            // Draw
            var HEIGHT = 50;
            // Set the width to the container
            var WIDTH = this.visWidth;
            var barWidth = (WIDTH / bufferLength) * 1.5;
            var c = this.canvasCtx;
            c.clearRect(0, 0, WIDTH, HEIGHT); // Redraw
            var x = 0;
            for (var i = 0; i < bufferLength; i++) {
                var barHeight = dataArray[i] / 2;
                c.fillStyle = "rgb(50," + (barHeight + 25) + "," + (barHeight + 100) + ")";
                c.fillRect(x, HEIGHT - barHeight / 2, barWidth, barHeight);
                x += barWidth + 1;
            }

            this.visLoopRequest = requestAnimationFrame(this.visualization);
        },
        // Track info
        getCurrentTrack() {
            axios
                .get(
                    URLS.API + "/station/" + this.STATE.player.station_slug + "/queue"
                )
                .then(({ data }) => {
                    let t = data.now_playing;
                    this.track = t;
                    this.track.image_full_url = t.image ? this.getTrackImage(t.image) : DEFAULT_IMAGE;
                    this.STATE.playerCurTrackBgImage = this.track.image_full_url;
                    document.title = this.track.name; // Change the page title to the track

                    var np = this.track;
                    this.remain  = np.time_played + np.seconds - np.time_server;
                    this.trackProgress(this.remain);
                    this.timeToChangeTrack(this.remain);

                });
        },
        trackProgress() {
            var sec = 50;
            clearInterval(this.interval);
            this.interval = setInterval(() => {
                this.remain -= sec/1000;
                
                // Progress bar
                this.percentProgress = (100 - (this.remain / this.track.seconds) * 100).toFixed(2) + 2;
                
                Mediasession.methods.setPositionState({
                    duration: this.track.seconds,
                    position: this.track.seconds - this.remain});
            }, sec);
        },
        timeToChangeTrack(remain) {
            var sec = 1000
            setTimeout(this.getCurrentTrack, (remain * sec) + (2 * sec));
        }
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
    }
};
