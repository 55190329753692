const DEFAULT_IMAGE = "/icons/placeholder_icon.png";
import { URLS } from "@/store";

export default {

    methods: {
        getStreamIcon: function (station_slug) {
            return `${URLS.Site}/img/stream_icons/128/${station_slug}.128.png`;
        },
        getTrackImage: function (image) {
            return image ? `${URLS.TrackImages}${image}` : DEFAULT_IMAGE;
        },

    }
};