<template>
  <div
    :class="[STATE.playerCurTrackBgImage ? 'player-background-image' : '']"
    :style="{backgroundImage: 'url(\''+STATE.playerCurTrackBgImage+'\')'}"
  >
    <div id="site-container" :style="bgColor">
        <Header />
        <main>
            <transition :name="transitionName" mode="out-in">
                <router-view :key="$route.fullPath" />
            </transition>
        </main>
        <aside id="player-container">
          <Player />
        </aside>
        <v-dialog :clickToClose="false" />
        <modal-notice />
    </div>
  </div>
</template>

<script>
import { ApiCalls } from "@/components/utils/api-calls";

import Header from "@/components/layout/Header.vue";
import Player from "@/components/ui/Player.vue";
import ModalNotice from "@/components/modal/modal-notice.vue";

import { store } from "@/store";

const DEFAULT_TRANSITION = "fade";

export default {
  name: "app",
  data() {
    return {
      STATE: store.state,
      transitionName: DEFAULT_TRANSITION
    };
  },
  components: {
    Header,
    Player,
    ModalNotice
  },
  async created() {
    // Get the stations from the API
    const stations = await ApiCalls.station.ListStations();
    store.setStations(stations);

    // Check the route and load the station if not set
    if (this.$route.params.id && !this.STATE.station) {
      store.setStation(this.$route.params.id);
    }

    // Load up user favorites list
    this.$userInit();

    // Do Transition stuff between clicks
    this.$router.beforeEach((to, from, next) => {
      let transitionName = to.meta.transitionName || from.meta.transitionName;

      var calcPath = path => {
        return path === "/" ? 1 : path.split("/").length;
      };

      if (transitionName === "slide") {
        const toDepth = calcPath(to.path);
        const fromDepth = calcPath(from.path);
        transitionName =
          toDepth === fromDepth
            ? DEFAULT_TRANSITION
            : toDepth < fromDepth
              ? "slide-right"
              : "slide-left";
      }

      this.transitionName = transitionName || DEFAULT_TRANSITION;
      next();
    });

  },
  watch: {},
  computed: {
    bgColor() {
      if (this.$route.name == "station" && store.state.station) {
        return {
          backgroundColor: `rgba(${store.state.station.meta.color_web})`
        };
      }
      return "";
    }
  },
  mounted() { },
  provide: function () {
    return {
      hideDialog() {
        this.$modal.hide("dialog");
      },
      showOk(message, cb) {
        this.$modal.show("dialog", {
          text: message,
          buttons: [
            {
              title: "OK",
              handler: () => {
                this.hideDialog();
                if (cb) cb();
              }
            }
          ]
        });
      },
      showConfirmation(message, cbOk, cbCancel) {
        this.$modal.show("dialog", {
          text: message,
          buttons: [
            {
              title: "OK",
              handler: () => {
                this.hideDialog();
                if (cbOk) cbOk();
              }
            },
            {
              title: "Cancel",
              handler: () => {
                this.hideDialog();
                if (cbCancel) cbCancel();
              }
            }
          ]
        });
      },
      showLogin(message = "Please Login to Proceed") {
        this.$modal.show("dialog", {
          text: `<center>${message}</center>`,
          buttons: [
            {
              title: "Go to Login",
              handler: async () => {
                this.$modal.hide("dialog")
                await this.$auth.PerformLoginAndRedirect();
              }
            },
            {
              title: "Cancel",
              handler: () => {
                this.$modal.hide("dialog")

              }
            }
          ]
        });
      },
      showNotice(message, params) {
        params = params || {};
        params.text = message;
        this.$modal.show("modal-notice", params);
      },
      showWait() {
        this.$modal.show("dialog", {
          text:
            "<center>Please wait</center></br><center><i class='fas fa-cog fa-spin fa-2x'style='color:#888;align:center'/></center>",
          buttons: [],
          clickToClose: false
        });
      }
    };
  }
};
</script>

<style lang="stylus">
@import 'assets/stylus/reset.styl'
@import 'assets/stylus/layout.styl'
@import 'assets/stylus/pretty.styl'
@import 'assets/stylus/buttons.styl'
@import 'assets/stylus/station-and-playlist.styl'

.slide-left-enter-active, .slide-left-leave-active, .slide-right-enter-active, .slide-right-leave-active
  overflow hidden
  transition-duration 0.3s
  transition-property height, opacity, transform
  transition-timing-function cubic-bezier(0.55, 0, 0.1, 1)

.slide-left-enter, .slide-right-leave-active
  opacity 0
  transform translate(2em, 0)

.slide-left-leave-active, .slide-right-enter
  opacity 0
  transform translate(-2em, 0)

.vue-dialog-content
  color #000

.vue-dialog-buttons
  color #000
  button
    transition background-color 200ms linear
    &:hover
      background: rgba(0,0,100,25%)
</style>
