<template>
  <div class="group">
    <div class="login-part" id="left">
      <div class="title">Create Account</div>
      <div class="api-error" v-if="apiError" :class="apiError?'invalid':''">{{this.apiError}}</div>
      <form>
        <validated-field
          :validations="$v.credentials.email"
          v-model="credentials.email"
          autocomplete="section-create email"
          fieldname="Email Address"
          fieldid="n-email"
          fieldtype="email"
        />
        <validated-field
          :validations="$v.credentials.newuser"
          v-model="credentials.newuser"
          autocomplete="section-create username"
          fieldname="Username"
          fieldid="n-newusername"
          fieldtype="text"
        />
        <validated-field
          :validations="$v.credentials.newpassword"
          v-model="credentials.newpassword"
          autocomplete="section-create new-password"
          fieldname="Password"
          fieldid="n-newpassword"
          fieldtype="password"
        />
        <validated-field
          :validations="$v.credentials.passwordverify"
          v-model="credentials.passwordverify"
          autocomplete="section-create new-password"
          fieldname="Verify Password"
          fieldid="n-passwordverify"
          fieldtype="password"
        />

        <validated-checkbox
          :validations="$v.policy.terms"
          v-model="policy.terms"
          labeltext="I agree with the site "
          fieldid="cb-terms"
        >
          <a href="#" @click="showPolicyText('terms')">Terms and Conditions</a>
        </validated-checkbox>

        <validated-checkbox
          :validations="$v.policy.privacy"
          v-model="policy.privacy"
          labeltext="I have read the "
          fieldid="cb-privacy"
        >
          <a href="#" @click="showPolicyText('privacy')">Privacy Policy</a>
        </validated-checkbox>
        </form>
      <div class="button-group col">
        <button class="large-btn register-btn" @click="register">Register</button>
      </div>
    </div>
    <!-- <div class="login-part" id="right"></div> -->
  </div>
</template>

<script>
import { store } from "@/store";
import { required, sameAs } from "vuelidate/lib/validators";
import ValidatedField from "@/components/validation/validated-field";
import ValidatedCheckbox from "@/components/validation/validated-checkbox";

import { TokenValidation } from "@/components/auth/tokenvalidation";
import { PolicyText } from "@/consts/policy-text";


export default {
  name: "login",
  inject: ["hideDialog", "showOk", "showNotice", "showWait"],
  data() {
    return {
      credentials: {
        email: "",
        newuser: "",
        newpassword: "",
        passwordverify: ""
      },
      policy: {
        terms: false,
        privacy: false
      },
      apiError: null
    };
  },
  components: {
    ValidatedField,
    ValidatedCheckbox
  },
  created() {},
  validations: {
    credentials: {
      email: { required },
      newuser: { required },
      newpassword: { required },
      passwordverify: { required, sameAsPassword: sameAs("newpassword") }
    },
    policy: {
      terms: { termsValidate: sameAs(() => true) },
      privacy: { privacyValidate: sameAs(() => true) },
    }
  },
  watch: {},
  computed: {},
  mounted() {},

  methods: {
    register: function() {
      //Validate
      this.$v.credentials.$touch();
      this.$v.policy.$touch();

      if (this.$v.credentials.$error || this.$v.policy.$error) return; //If not validated stop

      if (this.$auth.isAuthenticated()) {
        this.$auth.forcedLogout();
      }
      this.showWait();
      this.$auth
        .register({
          user: this.credentials.newuser,
          password: this.credentials.newpassword,
          email: this.credentials.email
        })
        .then(this.performAfterLogin.bind(this));
    },

    performAfterLogin: function(response) {
      if (response.data.error) {
        this.hideDialog();
        this.apiError = response.data.error;
      } else {
        TokenValidation.validateToken(response.data.token)
          .then(() => {
            this.hideDialog();
            this.showOk("Account created!", () => {
              store.navigate('/account-settings');
            });
          })
          .catch(() => {
            this.hideDialog();
          });
      }
    },
    showPolicyText:function(policyText){
        this.showNotice(PolicyText[policyText]);
    }

  }
};
</script>

// <style lang="stylus" src="@/assets/stylus/auth-styling.styl"/>
