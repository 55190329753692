import { store, URLS } from "@/store";

const jwt = require("jsonwebtoken");
const jwksClient = require("jwks-rsa");

export const TokenValidation = (() => {

    var _client;
    const GetClient = function () {
        return _client ?? (_client = jwksClient({
            strictSsl: false,
            cache: true,
            cacheMaxEntries: 5,
            cacheMaxAge: 600000,
            rateLimit: true,
            jwksRequestsPerMinute: 10, // Default value
            jwksUri: `${URLS.API}/auth/keys`
        }));
    };

    return {
        validateToken: function (id_token) {
            var client = GetClient();

            var getJwkPublicKey = (header, callback) => {
                client.getSigningKey(header.kid, (err, key) => {
                    var signingKey = key.getPublicKey();
                    signingKey = key.rsaPublicKey || key.publicKey;
                    callback(null, signingKey);
                });
            };

            return new Promise((res, rej) => {
                jwt.verify(id_token, getJwkPublicKey, (jwterr) => {
                    if (jwterr) {
                        rej(jwterr);
                        return;
                    }
                    res();
                });
            });
        },
        validateTokenAsync: async function (id_token) {
            var client = GetClient();

            var getJwkPublicKey = (header, callback) => {
                client.getSigningKey(header.kid, (err, key) => {
                    var signingKey = key.getPublicKey();
                    signingKey = key.rsaPublicKey || key.publicKey;
                    callback(null, signingKey);
                });
            };

            jwt.verify(id_token, getJwkPublicKey, (jwterr) => {
                if (jwterr) {
                    return jwterr;
                }
            });

            return null;

        }
    };
})();

