export default {
    methods: {
        only_time_chars: s => s.replace(/[^0-9:]/g, ''),
        convert_to_sec: t => +(String(t).split(':').reduce((acc,time) => (60 * acc) + +time)),
        sec_to_h: sec => {
            //if(sec <= 0 && sec >= 10) { return "00:00"; }
            sec = Math.round(sec)
            if (sec <= 0 || !sec) { return ""; }
            if (sec <= 0) { return "??:??"; }
            if (sec < 60) { return "0:" + (sec < 10 ? "0" + sec : sec); }
            var s = sec % 60;
            var m = Math.floor(sec / 60);
            return m + ":" + (s < 10 ? "0" + s : s);
        },

        date_to_12hr: d => {
            var hours = d.getHours();
            var m = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
            var s = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
            return ((hours + 11) % 12 + 1) + ":" + m + ":" + s + (hours >= 12 ? 'pm' : 'am');
        },

        sec_to_12hr: sec => {
            var d = new Date(sec * 1000) // Sec to Milli
            var hours = d.getHours();
            var m = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
            var s = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
            return ((hours + 11) % 12 + 1) + ":" + m + ":" + s + (hours >= 12 ? 'pm' : 'am');
        }

    }
}