<template>
  <div @click="viewStation(station)" class="station" :style="bgColor">
    <div class="station-header">
      <div class="station-icon" @click.stop="setPlayerAndPlay()">
        <img
          :src="this.getStreamIcon(station.meta.icon)"
        />
        <i class="fa fa-play"></i>
      </div>
      <div class="station-name">{{ station.station_name }}</div>
    </div>

      <div v-if="message.text" class="message" :class="message.class">
        {{ message.text }}
      </div>
    <div class="playlist" v-if="playlist">
      <table>
        <tr>
          <td class="countdown">
            <img
              class="track_placeholder enlarge"
              :src="playlist.now_playing.track_image"
            />
            <div></div>
          </td>
          <td class="title">
            {{ this.$get(playlist,"now_playing.name", "" ) | trackTitleFilter }}
            <LinkUserRequestBy :user_id='playlist.now_playing.user_id' :user_name='playlist.now_playing.user_name' />
            &nbsp; <ButtonUserFavorite :track_id="playlist.now_playing.track_id" />
            <div class="progress-bar-simple">
              <div :style="{ width: percentProgress + '%' }"></div>
            </div>
          </td>
          <td class="mono tar">{{ sec_to_h(remain) }}</td>
        </tr>

        <tbody class="home">
          <tr>
            <td class="tar">Next:</td>
            <td>
              {{ this.$get(playlist,"queue[0].name", "") | trackTitleFilter }}
              <span class="mono tar">{{ this.$get(playlist,'queue[0].seconds_h', "0:00") }}</span>
              <LinkUserRequestBy :user_id='this.$get(playlist,"queue[0].user_id")' :user_name='this.$get(playlist,"queue[0].user_name")' />
              &nbsp; <ButtonUserFavorite :track_id="playlist.queue[0].track_id" />
            </td>

            <td class="mono tar">{{ this.$get(playlist,'queue[0].when_play', "0:00") }}</td>
          </tr>
          <!--
          <tr>
            <td></td>
            <td>
              {{playlist.queue[1].name}}
              <span v-if="playlist.queue[1].user_name">
                &nbsp;
                by
                <i>{{ playlist.queue[1].user_name }}</i>
              </span>
            </td>
            <td class="mono tar">{{playlist.queue[1].seconds_h}}</td>
            <td class="mono tar">{{ playlist.queue[1].when_play }}</td>
          </tr>
          -->
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { store } from "@/store.js";
import Queue from "@/mixins/queue.js";
import SrUtils from "@/mixins/srutils.js";
import ButtonUserFavorite from "@/components/ui/ButtonUserFavorite.vue";
import LinkUserRequestBy from "@/components/ui/LinkUserRequestBy.vue";

export default {
  props: ["station"],
  mixins: [Queue, SrUtils],
  components: {
    ButtonUserFavorite,
    LinkUserRequestBy,
  },
  data() {
    return {
      STATE: store.state,
      playlist: false,
      remain: false,
      remain_date: false,
      percentProgress: false,
      interval: false,
    };
  },
  watch: {
    // For the broswer back and forward buttons
    "$route.params": {
      handler: function (params) {
        if (params.id == this.station.station_slug) {
          store.viewStation(this.station);
        }
      },
    },
  },

  mounted() {
    // Check the route and load the station if it's this one
    if (this.$route.params.id == this.station.station_slug) {
      store.viewStation(this.station);
    }
    this.getQueue(this.station.station_slug);
  },
  computed: {
    bgColor() {
      return {
        backgroundColor: `rgba(${this.station.meta.color_web})`
      };
    },
  },
  methods: {
    viewStation: function (station_info) {
      store.viewStation(station_info);
    },
    setPlayerAndPlay() {
      // Station should already be set if we are in the station view
      store.state.player = this.station;
      //store.setPlayerAndPlay();
    },
  },
};
</script>

<style scoped lang="stylus">
// Media Queries
@media screen and (max-width: 768px)
  .title
    font-size 14px

.station
  padding 10px
  border 1px solid rgba(255, 255, 255, 0.25)
  margin 5px
  border-radius 5px
  cursor pointer
  box-shadow 2px 2px 7px #000
  transition all 200ms ease-in

  &:hover
    background rgba(255, 255, 255, 0.25)
    box-shadow 0 0 10px #fff
  .station-header
    display flex
    align-items center
    margin-bottom 10px
</style>
