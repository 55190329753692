<template>
    <sr-faq-template :faq-data="faqdata" />
</template>

<script>
import { store } from "@/store";
import srFaqTemplate from '@/components/vue-material/sr-faq-template.vue';
import { ApiCalls } from "@/components/utils/api-calls";

export default {
  components: {
    srFaqTemplate
  },
  data() {
    return {
      STORE: store,
      faqdata: {
        sections: []
      }
    };
  },
  async created() {
    this.faqdata.sections = await ApiCalls.system.GetFaqSections();
  },
  methods: {},
  async mounted() {
    //console.log(data);
    //console.log(this.faqdata)
    //data.forEach(e => {
    //  e.items = e.items.map(i => { return { ...i, ...{ id: this.$uuid.v4() } }; });
    //  console.log(e);
    //});
    //console.log(this.$marked);
    //this.faqdata.sections = data;
  },
};
</script>