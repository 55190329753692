import { validationMixin } from 'vuelidate'
export default {

    mixins: [
        validationMixin
    ],

    data: function () {
        return {

        }
    },

    async mounted() {
    },

    methods: {
        getMaxLength(fieldName) {
            return this.$get(this.$v, `${fieldName}.$params.maxLength.max`);
        },
        getValidationClass(fieldName) {
            const field = this.$get(this.$v, fieldName);
            if (field) {
                return {
                    "md-invalid": field.$error,
                };
            }
        },
    }
}