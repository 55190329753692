import { render, staticRenderFns } from "./WhatsNew.vue?vue&type=template&id=bad97e18&scoped=true&"
import script from "./WhatsNew.vue?vue&type=script&lang=js&"
export * from "./WhatsNew.vue?vue&type=script&lang=js&"
import style0 from "@/assets/stylus/material-design.styl?vue&type=style&index=0&lang=stylus&"
import style1 from "./WhatsNew.vue?vue&type=style&index=1&id=bad97e18&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bad97e18",
  null
  
)

export default component.exports