import Vue from 'vue';
import App from './App.vue';
import router from "@/routes.js";
import { store, URLS } from "@/store";

import VModal from 'vue-js-modal';
import Croppa from 'vue-croppa';
import VueAxios from 'vue-axios';
import VueAuthenticate from 'vue-authenticate';
import axios from 'axios';
import Vuelidate from 'vuelidate';
import './registerServiceWorker';
import VueMaterial from 'vue-material';
import VueGtag from "vue-gtag";

import { TokenValidation } from "@/components/auth/tokenvalidation";

import lodashGet from 'lodash/get';
import { PERMISSIONS } from '@/consts/permissions';
import UUID from 'vue-uuid';
import * as marked from "marked";
import moment from 'moment';
import { ApiCalls } from "@/components/utils/api-calls";
import "@/mixins/filters"

const TOKEN_REFRESH_TIME = 3; //Minutes before refreshing token

Vue.prototype.$get = lodashGet;
Vue.prototype.$marked = marked.marked;

Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.use(VModal, { dialog: true });
Vue.use(Vuelidate);
Vue.use(Croppa); // Image uploading tool

Vue.use(VueAxios, axios);
Vue.use(VueMaterial);
Vue.use(UUID);

Vue.use(VueAuthenticate, {
    baseUrl: URLS.API, // Your API domain
    loginUrl: '/auth/login',
    logoutUrl: '/auth/logout',
    registerUrl: '/auth/register',
    storageType: 'cookieStorage',
    tokenPath: 'token',
    tokenType: '',
    providers: {
        google: {
            //responseType: 'token',
            clientId: '',
            redirectUri: '' // Your client app URL
        },
        discord: {
            name: 'discord',
            url: '/auth/discord',
            authorizationEndpoint: 'https://discordapp.com/api/oauth2/authorize',
            requiredUrlParams: ['scope', 'prompt'],
            optionalUrlParams: ['display'],
            prompt: ['none'],
            scope: ['email', 'identify'],
            scopePrefix: '',
            scopeDelimiter: ' ',
            display: 'popup',
            oauthType: '2.0',
            popupOptions: { width: 452, height: 633 },

            clientId: '',
            redirectUri: '' // Your client app URL
        }
    },
});

Vue.use(VueGtag, {
    config: { id: "G-5KBRC14W8G" }
}, router);

Vue.use(PERMISSIONS);

//extend auth to add helper function
Vue.prototype.$auth.isValidAuth = async function () {
    if (!this.isAuthenticated()) {
        store.state.isAuthed = false;
        return false;
    }

    var jwterr = await TokenValidation.validateTokenAsync(this.getToken());
    store.state.isAuthed = true;
    return jwterr == null;
};

Vue.prototype.$auth.PerformLoginAndRedirect = async function () {
    if (await this.isValidAuth()) return;
    store.state.isAuthed = true;
    this.AfterLoginRoute = APP.$route;
    APP.$router.push({ name: 'login' });
};

Vue.prototype.$auth.RedirectAfterLogin = async function (defaultpath) {
    if (!await this.isValidAuth()) return;
    store.state.isAuthed = true;
    const path = (this.AfterLoginRoute) ? this.AfterLoginRoute.path : defaultpath ?? "/";
    this.AfterLoginRoute = undefined; //Clear route
    //console.log(`path:${path}`);
    APP.$router.push({ path });
};

Vue.prototype.$auth.KeepaliveToken = async function () {
    if (!await this.isValidAuth()) return;
    var p = this.getPayload();
    const elapsed = -moment.unix(p.iat).diff(moment.now(), 'minute');
    //console.log(`Token elapsed time (min):${elapsed}`);
    if (elapsed < TOKEN_REFRESH_TIME) return;
    var token = await ApiCalls.auth.RefreshToken();
    this.setToken(token);
};

Vue.prototype.$auth.HasPermission = function (keys) {
    var requestedPermissions = new Set(Array.isArray(keys) ? keys : [keys]);
    //console.log(`$auth::HasPermission - keys:[${requestedPermissions}]`)
    const userPermissions = new Set(this.Permissions);
    var intersect = [...requestedPermissions].filter(p => userPermissions.has(p));
    //console.log(intersect);
    return intersect.length == [...requestedPermissions].length;
};

Object.defineProperty(Vue.prototype.$auth, "AfterLoginRoute", {
    get: function () {
        return this._afterLoginRoute;
    },
    set: function (value) {
        this._afterLoginRoute = value;
    }
});

Object.defineProperty(Vue.prototype.$auth, "Permissions", {
    get: function () {
        var p = this.getPayload();
        return p == null ? [] : p.permissions ?? [];
    }
});

Object.defineProperty(Vue.prototype.$auth, "IsAdmin", {
    get: function () {

        //console.log(`IsAdmin: ${this.HasPermission(PERMISSIONS.VIEW_ADMIN_PANEL)}`);
        //console.log(`Requested Permission: ${PERMISSIONS.VIEW_ADMIN_PANEL}`)
        //console.log(`Permissions: ${this.Permissions}`)
        return this.HasPermission(PERMISSIONS.VIEW_ADMIN_PANEL);
    }
});

Vue.prototype.$auth.getUsername = function () {
    var p = this.getPayload();
    return p == null ? "" : p?.username;
};

Vue.prototype.$auth.getEmail = function () {
    var p = this.getPayload();
    return p == null ? "" : p?.email;
};

Vue.prototype.$auth.assertValidAuth = async function () {
    var isValid = await this.isValidAuth();
    if (!isValid) {
        this.$router.push({ name: 'login' });
        return false;
    }
    return true;
};

Vue.prototype.$auth.forcedLogout = async function () {
    store.state.isAuthed = false;
    if (!this.isAuthenticated()) {
        return;
    }
    //Server call can fail with 401 if token is invalid, so just clear the token.
    await this.logout().catch(() => {
        this.storage.removeItem(this.tokenName);
    });
};


// TODO - Would love to make this $user.init and in a separate file, but couldn't figure out how
// User Plugins
Vue.prototype.$userInit = async function() {
    if(await this.$auth.isValidAuth())
    {
      const { user_id } = this.$auth.getPayload() || {};
      store.state.user.favorites = await ApiCalls.user.GetFavorites(user_id, true);
    }
    // If the user had expert titles, fetch the title filters
    //store.titleFilters = await ApiCalls.system.GetTrackTitleFilters();
};


/* 
    Fix for vue-material bug
    https://github.com/vuematerial/vue-material/issues/2285
*/
import { MdField } from 'vue-material/dist/components'

Vue.use(MdField)

Vue.component('MdSelect', Vue.options.components.MdSelect.extend({
    methods: {
        isInvalidValue: function isInvalidValue () {
            return this.$el.validity ? this.$el.validity.badInput : this.$el.querySelector('input').validity.badInput
        }
    }
}))

export const APP = new Vue({
    router,
    data: {},

    render: h => h(App)
}).$mount('#app');

