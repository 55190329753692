/* RUN THIS TO GENERATE

select CONCAT('\t', p.code_name, ':\"', p.`key`, '\", //', p.display_name, ' - ', p.description)
from auth_permission p
order by p.code_name

*/
export const PERMISSIONS = {
    VIEW_ADMIN_PANEL: "66AD6F7E-1BC5-4E3A-A149-21D2FFF5AEA1", //View Admin Panel - Can view the admin screens
    VIEW_ADMIN_SUPPORT: "34C73E42-2D49-4C39-B8B4-631D8A48C8AB", //View Admin Support Panel - Can view User Management -> Support Queue
    EDIT_ADMIN_SUPPORT: "9550FE94-855E-4DED-B430-0A64E8506390", //Edit Admin Support Panel - Can edit User Management -> Support Queue
    VIEW_ADMIN_USER_LIST: "25807113-8808-40C2-9472-818AACA654FB", //View Admin User List - Can view User Management -> User List
    EDIT_ADMIN_USER_LIST: "F13611A7-60FB-4367-A8F4-D521B5E50BFE", //Edit Admin User List - Can edit User Management -> User List
    VIEW_ADMIN_FAQ: "9D42A174-131F-41CA-9F4A-178ED7B56916", //View Admin FAQ - Can view Content Management -> View / Edit FAQ
    EDIT_ADMIN_FAQ: "5DDF2AFD-DE43-4C06-AC58-F4D078AEE67D", //Edit Admin FAQ - Can edit Content Management -> View / Edit FAQ
    VIEW_ADMIN_QUEUE: "20119D79-DFC4-4CC6-BEF5-6972E20592FF", //View Admin Queue - Can view Queue Management -> View / Edit Queue
    EDIT_ADMIN_QUEUE: "64D552B5-5C77-4F15-9254-B8E4CA8F0197", //Edit Admin Queue - Can edit Queue Management -> View / Edit Queue
    VIEW_ADMIN_STATION_LIST: "4D2F2E25-E04F-46DE-9F62-8BC909B657EC", //View Admin Station List - Can view Station Management -> Station List
    EDIT_ADMIN_STATION_LIST: "B568931E-1946-4CA5-8A8E-EDF000901667", //Edit Admin Station List - Can edit Station Management -> Station List
    VIEW_ADMIN_TRACK_ASSIGNMENT: "A93C1D15-1EE3-4BBC-8C2C-AA9505379C5C", //View Admin Track Assignment - Can view Station Management -> Track Assignment
    EDIT_ADMIN_TRACK_ASSIGNMENT: "F1FD0DAA-FC48-4453-9FEF-221DD1BF5C67", //Edit Admin Track Assignment - Can edit Station Management -> Track Assignment
    VIEW_ADMIN_TRACK_LIST: "A97B9FAD-65DB-4DC3-93DB-169305883816", //View Admin Track List - Can view Track Management -> Track List
    EDIT_ADMIN_TRACK_LIST: "379BF3D9-20A7-4E9F-82B2-E64367A36B8C", //Edit Admin Track List - Can edit Track Management -> Track List
    VIEW_ADMIN_TRACK_IMAGES: "4398B0A0-0968-4076-A91E-489EAAAD6DE2", //View Admin Track Images - Can view Track Management -> Track Images
    EDIT_ADMIN_TRACK_IMAGES: "A96A3E8C-7AA4-4785-AFA6-451897F21107", //Edit Admin Track Images - Can edit Track Management -> Track Images
    EDIT_ADMIN_TRACK_IMPORT: "FF7E2B1B-CC3F-4B79-B474-D1CB372F777E", //Edit Admin Track Import - Can edit Track Management -> Track Import

};

PERMISSIONS.install = function (Vue) {
    //console.log(`PERMISSIONS::install()`)
    Vue.prototype.$getConst = Vue.prototype.$getConst ?? {};
    Vue.prototype.$getConst.PERMISSIONS = (key) => {
        //console.log(`$getConst::PERMISSIONS - key:'${key}'`)
        return PERMISSIONS[key];
    };
};

