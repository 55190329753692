<template>
  <error-list :validations="validations">
    <div class="field-group checkbox" slot-scope="{ errors, invalid }">
      <div class="input">
        <input v-model="localValue" :class="{ 'invalid': invalid }" :id="fieldid" type="checkbox" />
        <div class="label">
            <label :for="fieldid">{{labeltext}}</label>
            <span><slot></slot></span>
        </div>
      </div>
      <div class="error" :class="{ 'invalid': invalid }">
        <span v-for="error in errors" v-bind:key="error">{{ error }}</span>
      </div>
    </div>
  </error-list>
</template>

<script>
import ErrorList from "@/components/validation/error-list";

export default {
  components: {
    ErrorList
  },
  props: {
    validations: {
      required: true,
      type: Object
    },
    fieldid: {
      required: true,
      type: String
    },
    labeltext: {
      required: true,
      type: String
    },
    value: {
      required: true
      //type: Object
    }
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit("input", localValue);
      }
    }
  }
};
</script>
