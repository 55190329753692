<template lang="pug">
  span.request-by.hover(
    @click.stop="handleClick()"
    v-if='user_name'
  )
    i  [{{ user_name }}]
</template>

<script>
import { store } from "@/store";

export default {
  props: {
    user_id: Number,
    user_name: String,
  },
  methods: {
    async handleClick() {
      store.navigate(`/user/favorites/${this.user_id}`);
    },

  },
};
</script>