import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from "@/pages/home/Home.vue";
import Station from "@/pages/station/Station.vue";

import Authenticate from "@/pages/auth/Authenticate.vue";
import Chat from "@/pages/misc/Chat.vue";
import SRApps from "@/pages/misc/SRApps.vue";
import Support from "@/pages/misc/Support.vue";
import WhatsNew from "@/pages/misc/WhatsNew.vue";
import Faq from "@/pages/misc/FAQ.vue";
import TopTracks from "@/pages/misc/TopTracks.vue";

import { PERMISSIONS } from '@/consts/permissions.js';

const lazyLoad = function (componentPath) {
    return () => import(`@/pages/${componentPath}.vue`);
};

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: "./",
    routes: [
        { path: '/', component: Home },
        // -- Station --
        { path: '/station/:id', name: 'station', component: Station },

        // -- User --
        { path: '/user/favorites/:user_id?', component: lazyLoad('user/Favorites') },
        { path: '/account-settings', component: lazyLoad('auth/AccountSettings'), meta: { requiresAuth: true } },
        { path: '/account-settings/change-email', component: lazyLoad('auth/ChangeEmail'), meta: { requiresAuth: true } },
        { path: '/account-settings/change-password', component: lazyLoad('auth/ChangePassword'), meta: { requiresAuth: true } },
        { path: '/account-settings/forgot-password', name: 'ForgotPassword', component: lazyLoad('auth/ForgotPassword'), meta: { requiresAuth: false } },
        { path: '/account-settings/reset-password/:tokendata?', component: lazyLoad('auth/ResetPassword'), meta: { requiresAuth: false } },
        { path: '/login', name: 'login', component: Authenticate, },
        //{ path: '/auth/callback', component: { template: '<div class="auth-component"></div>' } },

        // -- Misc --
        { path: '/chat', component: Chat },
        { path: '/apps', component: SRApps },
        { path: '/support', name: "Support", component: Support },
        { path: '/whats-new', name: "WhatsNew", component: WhatsNew },
        { path: '/frequently-asked-questions', name: "FAQ", component: Faq },
        { path: '/top-tracks', name: "TopTracks", component: TopTracks },

        // -- Admin --
        {
            path: '/admin/',
            component: lazyLoad('admin/AdminLanding'),
            meta: { requiresPermission: PERMISSIONS.VIEW_ADMIN_PANEL },
            redirect: '/admin/support/',
            children: [
                { path: '/admin/station/:id?', name: 'TrackAssignment', component: lazyLoad('admin/AdminStation'), meta: { requiresPermission: PERMISSIONS.VIEW_ADMIN_TRACK_ASSIGNMENT } },
                { path: '/admin/station/v2', name: 'TrackAssignmentV2', component: lazyLoad('admin/AdminStationV2StationColumns'), meta: { requiresPermission: PERMISSIONS.VIEW_ADMIN_TRACK_ASSIGNMENT } },
                { path: '/admin/track/images/', name: 'TrackImages', component: lazyLoad('admin/AdminTrackImages'), meta: { requiresPermission: PERMISSIONS.VIEW_ADMIN_TRACK_IMAGES } },
                { path: '/admin/track/import/', name: 'TrackImport', component: lazyLoad('admin/AdminTrackImport'), meta: { requiresPermission: PERMISSIONS.EDIT_ADMIN_TRACK_IMPORT } },
                { path: '/admin/track/list/', name: 'TrackList', component: lazyLoad('admin/AdminTrackList'), meta: { requiresPermission: PERMISSIONS.VIEW_ADMIN_TRACK_LIST } },
                { path: '/admin/user/', name: 'UserList', component: lazyLoad('admin/AdminUserList'), meta: { requiresPermission: PERMISSIONS.VIEW_ADMIN_USER_LIST } },
                { path: '/admin/queue/', name: 'ViewEditQueue', component: lazyLoad('admin/AdminViewEditQueue'), meta: { requiresPermission: PERMISSIONS.VIEW_ADMIN_QUEUE } },
                { path: '/admin/support/', name: 'SupportQueue', component: lazyLoad('admin/AdminSupportQueue'), meta: { requiresPermission: PERMISSIONS.VIEW_ADMIN_SUPPORT } },
                { path: '/admin/faq/', name: 'ViewEditFaq', component: lazyLoad('admin/AdminViewEditFAQ'), meta: { requiresPermission: PERMISSIONS.VIEW_ADMIN_PANEL } },
            ]
        }
    ]
});

const checkHasAdmin = () => { return router.app.$auth.isAdmin(); };
const checkHasPermissions = (keys) => {
    const userPermissions = new Set(router.app.$auth.Permissions);
    var intersect = [...new Set(keys)].filter(p => userPermissions.has(p));
    return intersect.length == keys.length;
};

// Check for Auth
router.beforeEach(async (to, from, next) => {

    var reqAdmin = to.matched.some(r => r.meta.requiresAdmin == true);
    var reqPermission = to.matched.some(r => r.meta.requiresPermission != undefined);
    var reqAuth = reqAdmin || reqPermission || to.matched.some(r => r.meta.requiresAuth);

    var requiredPermissions = to.matched.map(r => r.meta.requiresPermission);

    /*
    console.log(`requiresAuth: ${reqAuth}`);
    console.log(`requiresAdmin: ${reqAdmin}`);
    console.log(`requiresPermission: ${reqPermission}`);
    */

    if (!reqAuth) {
        next();
        return;
    }

    var isValid = await router.app.$auth.isValidAuth();
    //console.log(isValid);
    if (isValid) {
        var okToNavigate = true;

        if (reqAdmin || reqPermission) {
            okToNavigate = false;
            //console.log(`Admin Check - Start - ${okToNavigate}`);
            okToNavigate |= (reqAdmin) ? router.app.$auth.IsAdmin : okToNavigate;
            //console.log(`Admin Check - reqAdminCheck - ${okToNavigate}`);
            okToNavigate |= (reqPermission) ? router.app.$auth.HasPermission(requiredPermissions) : okToNavigate;
            //console.log(`Admin Check - reqPermissionCheck - ${okToNavigate}`);
        }

        if (okToNavigate) {
            next();
            return;
        }
        next(from);
        console.log('Permissions Error');
        //console.log(from);
        return;
    }
    router.app.$auth.AfterLoginRoute = to;
    next('/login');
    return;
});




export default router;