<template lang="pug">
  header
    .logo(@click="showHome()")
      img(:src="`${URLS.Site}/img/icons/sr_logo_white_64x64.png`")
      | Subsonic Radio

    .main-menu.header-menu
      | Menu &nbsp;
      i.fa-solid.fa-bars
      .header-menu-hover

        .link.hover.divider(@click="STORE.navigate('/top-tracks')")
          i.fa-solid.fa-chart-simple
          | Stats : Top Lists

        .link.hover(@click="STORE.navigate('/chat')")
          i.fa.fa-comment
          | Discord Chat

        .link.hover(@click="externalLink('https://shop.spreadshirt.com/subsonicradio/')")
          i.fa.fa-store
          | Store
          span
            i.fas.fa-external-link-alt

        .link.hover(@click="externalLink('https://forums.subsonicradio.com')")
          i.fa.fa-book
          | Forums
          span
            i.fas.fa-external-link-alt

        .link.hover.divider(@click="STORE.navigate('/apps')")
          i.fa-solid.fa-mobile-screen-button
          | Apps
          span
            i.fab.fa-apple
            i.fab.fa-android
            i.fab.fa-microsoft
            i.fab.fa-amazon

        //-
          .link.hover(@click="STORE.navigate('/frequently-asked-questions')")
            i.fa-solid.fa-clipboard-question
            | FAQ

        .link.hover(@click="STORE.navigate('/support')")
          i.fa.fa-paper-plane
          | Contact Us

    AuthMenu
</template>

<script>
import { store, URLS } from "@/store";
import AuthMenu from "@/components/auth/AuthMenu.vue";

export default {
  data() {
    return {
      STORE: store,
      URLS: URLS,
    };
  },
  components: {
    AuthMenu
  },
  created() {
  },
  methods: {
    showHome: function() {
      store.showHome();
    },
    externalLink: function(url) {
      window.open(url, "_blank");
    },
  }
}
</script>


<style scoped lang="stylus">
@import '~/src/assets/stylus/variables.styl';

header
  display flex
  justify-content space-between
  //align-items center
  box-shadow 0 10px 5px -5px #000
  background linear-gradient(0deg, rgb(30,30,30) 0%, rgb(20,20,20) 100%);
  > div
    display flex
    align-items center
    padding 6px 10px
    font-weight bold

.logo
  cursor pointer
  font-style italic
  shadow-blue()
  font-size 24px
  font-weight bold
  img
    height 42px
    border-radius 50%
    margin-right 10px

  &:hover
    img
      box-shadow: 0 0 10px #fff
    shadow-white()

.main-menu
  margin-left auto // Push the menu to the right
  border-right 1px solid #666
  border-left 1px solid #666

</style>
