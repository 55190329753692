import Axios from "axios";
import { URLS } from "@/store";
import router from "@/routes.js";
import lodashGet from 'lodash/get';
import { TokenValidation } from "@/components/auth/tokenvalidation";

const commonApiRequestHandler = function (apirequest, responsehandler) {
    return new Promise(async (resolve, reject) => {
        var response = await apirequest;
        if (response.data.error) { return reject(response.data.error); }
        else {
            try { return responsehandler(response, resolve); }
            catch (err) { console.log(err); }
        }
    });
};

const asyncCommonApiRequestHandler = async function (apirequest, ignoreRefresh = false) {
    const response = await apirequest();
    if (response.data.error) throw response.data.error;
    if (response.data.apierror) {
        console.log(`$[ApiError]:${response.data.apierror}`);
        return;
    }
    if (!ignoreRefresh) await handleTokenRefresh();
    return response.data;
};

const handleTokenRefresh = async function () {
    const auth = lodashGet(router, `app.$auth`);
    await auth.KeepaliveToken();
};

const createAuthenticatedWSConnection = async function (route) {
    //const auth = lodashGet(router, `app.$auth`);
    const otp = await ApiCalls.auth.GenOTP();
    const socket = new WebSocket(`${URLS.WS}${route}/?a=${otp}`);
    return socket;
};

export const ApiCalls = {
    admin: {
        track: {
            EditTrack: async function (track) {
                return asyncCommonApiRequestHandler(() => Axios.post(`${URLS.API}/admin/track/${track.track_id}`, track));
            },
            ListTracks: async function (page, maxResults, search) {
                return asyncCommonApiRequestHandler(() => Axios.get(`${URLS.API}/admin/track/${page}-${maxResults}/${search}`));
            },
            GetAssignableMetadata: async function () {
                return asyncCommonApiRequestHandler(() => Axios.get(`${URLS.API}/admin/track-metadata`));
            },
            GetTrack: async function (track) {
                return asyncCommonApiRequestHandler(() => Axios.get(`${URLS.API}/admin/track/${track.track_id}`));
            },
            GetPreviewTrackLink: (track, token) => {
                return `${URLS.API}/admin/track/preview-${track.track_id}?a=${token}`;
            },
            GetTrackImportFolderList: async function () {
                return asyncCommonApiRequestHandler(() => Axios.get(`${URLS.API}/admin/track-scan`));
            },
            TrackImport: async function (rootFolder, cancel) {
                var params = (rootFolder) ? { root: rootFolder } : {};
                params = { ...params, ...((cancel) ? { cancel } : {}) };

                return asyncCommonApiRequestHandler(() => Axios.post(`${URLS.API}/admin/track-scan`, params));
            },
        },
        system: {
            AvailableRoles: async function () {
                return asyncCommonApiRequestHandler(() => Axios.get(`${URLS.API}/admin/user/roles`));
            },
        },
        user: {
            EditUser: async function (user) {
                return asyncCommonApiRequestHandler(() => Axios.post(`${URLS.API}/admin/user/${user.user_id}`, user));
            },
            GetRoles: async function (user) {
                return asyncCommonApiRequestHandler(() => Axios.get(`${URLS.API}/admin/user/roles/${user.user_id}`));
            },
            ListUsers: async function (page, maxResults, search) {
                return asyncCommonApiRequestHandler(() => Axios.get(`${URLS.API}/admin/user/${page}-${maxResults}/${search}`));
            },
            ListSupportRequests: async function (page, maxResults, search) {
                return asyncCommonApiRequestHandler(() => Axios.get(`${URLS.API}/admin/support/${page}-${maxResults}/${search}`));
            },
            UpdateRoles: async function (user, roles) {
                return asyncCommonApiRequestHandler(() => Axios.post(`${URLS.API}/admin/user/roles/${user.user_id}`, { roles }));
            },
        },
        faq: {
            AddUpdateItem: async function (item) {
                return asyncCommonApiRequestHandler(() => Axios.post(`${URLS.API}/admin/faq/item`, item));
            },
            AddUpdateSection: async function (section) {
                return asyncCommonApiRequestHandler(() => Axios.post(`${URLS.API}/admin/faq/section`, section));
            },
            GetSections: async function () {
                return asyncCommonApiRequestHandler(() => Axios.get(`${URLS.API}/admin/faq/sections`));
            },
            GetSectionItems: async function (sectionId) {
                return asyncCommonApiRequestHandler(() => Axios.post(`${URLS.API}/admin/faq/section/${sectionId}/items`));
            },
            RemoveItem: async function (itemId) {
                return asyncCommonApiRequestHandler(() => Axios.delete(`${URLS.API}/admin/faq/item/${itemId}`));
            },
            RemoveSection: async function (sectionId) {
                return asyncCommonApiRequestHandler(() => Axios.delete(`${URLS.API}/admin/faq/section/${sectionId}`));
            },
            ReorderItem: async function (itemId, order) {
                return asyncCommonApiRequestHandler(() => Axios.post(`${URLS.API}/admin/faq/item/${itemId}/${order}`));
            },
            ReorderSection: async function (sectionId, order) {
                return asyncCommonApiRequestHandler(() => Axios.post(`${URLS.API}/admin/faq/section/${sectionId}/${order}`));
            },
        },
    },
    auth: {
        ChangeEmail: function (email, password) {
            return commonApiRequestHandler(
                Axios.post(`${URLS.API}/auth/changeemail`, { email, password }),
                async (response, resolve) => {
                    await TokenValidation.validateToken(response.data.token);
                    return resolve(response.data);
                }
            );
        },
        ChangePassword: function (oldpassword, newpassword) {
            return commonApiRequestHandler(
                Axios.post(`${URLS.API}/auth/changepw`, { oldpassword, newpassword }),
                async (response, resolve) => {
                    return resolve(response.data);
                }
            );
        },
        ForgotPassword: async function (user) {
            return asyncCommonApiRequestHandler(() => Axios.post(`${URLS.API}/auth/forgotpassword/`, { user }));
        },
        VerifyResetPassword: async function (tokendata) {
            var url = Buffer.from(tokendata, 'base64').toString('ascii');
            return asyncCommonApiRequestHandler(() => Axios.get(`${URLS.API}/auth/resetpassword/${url}`));
        },
        ResetPassword: async function (tokendata, password) {
            var url = Buffer.from(tokendata, 'base64').toString('ascii');
            return asyncCommonApiRequestHandler(() => Axios.post(`${URLS.API}/auth/resetpassword/${url}`, { password }));
        },
        RefreshToken: async function () {
            return asyncCommonApiRequestHandler(() => Axios.post(`${URLS.API}/auth/refresh`), true);
        },
        GenOTP: async function () {
            return asyncCommonApiRequestHandler(() => Axios.get(`${URLS.API}/auth/gen-otp`), true);
        },
    },

    station: {
        ListStations: async function () {
            return asyncCommonApiRequestHandler(() => Axios.get(`${URLS.API}/station`));
        },
        StationQueue: async function (station) {
            return asyncCommonApiRequestHandler(() => Axios.get(`${URLS.API}/station/${station}/queue`));
        },
    },

    system: {
        GetFaqSections: async function (data, recaptcha) {
            return asyncCommonApiRequestHandler(() => Axios.get(`${URLS.API}/faq/sections`));
        },
        SendSupportEmail: async function (data, recaptcha) {
            return asyncCommonApiRequestHandler(() => Axios.post(`${URLS.API}/system/support/${recaptcha}`, data));
        },
        GetTrackTitleFilters: async function () {
            return asyncCommonApiRequestHandler(() => Axios.get(`${URLS.API}/system/track-title-filters`));
        },
    },

    stats: {
        GetTopTracks: async function (list, timeSpan) {
            return asyncCommonApiRequestHandler(() => Axios.get(`${URLS.API}/stats/top/${list}?timeSpan=${timeSpan}`));
        },
    },

    user: {
        GetUser: async function (user_id) {
            return asyncCommonApiRequestHandler(() => Axios.get(`${URLS.API}/user/${user_id}`));
        },
        GetFavorites: async function (user_id, only_ids = '') {
            return asyncCommonApiRequestHandler(() => Axios.get(`${URLS.API}/user/${user_id}/track-favorites/?only_ids=${only_ids}`));
        },
        AddFavorite: async function (user_id, track_id) {
            return asyncCommonApiRequestHandler(() => Axios.post(`${URLS.API}/user/${user_id}/track-favorites/${track_id}`));
        },
        RemoveFavorite: async function (user_id, track_id) {
            return asyncCommonApiRequestHandler(() => Axios.delete(`${URLS.API}/user/${user_id}/track-favorites/${track_id}`));
        },
    }
};

export const SocketCalls = {
    admin: {
        TrackImport: async function () { return await createAuthenticatedWSConnection('/admin/track-scan'); }
    },
};
