<template>
  <div ref="wtParent" class="waveTableBox">
    <canvas ref="waveTable"></canvas>
  </div>
</template>

<script>
// audiowaveform -i Loop.mp3   -o /var/www/www.subsonicradio.com/live/waveform_loop.json -b 8 --amplitude-scale auto --pixels-per-second 5
import _ from "lodash";

export default {
  props: {
    wave_data: {
      // Make some default wavedata
      default: function() {
        return new Array(100).fill(0);
      }
    },
    progress: {
      default: 100
    },
    color: {
      default: "rgba(255, 255, 255, 0.5)"
    },
    height: {}
  },
  data() {
    return {};
  },
  watch: {
    wave_data() {
      this.handleResize();
    },
    progress() {
      this.drawWT();
    }
  },
  mounted() {
    this.cWT = this.$refs.waveTable;
    this.cWT.height = this.height;
    this.cWT_ctx = this.cWT.getContext("2d"); // Get the canvas container

    window.addEventListener("resize", _.debounce(this.handleResize, 1));

    this.handleResize();
  },
  methods: {
    // The initializer
    handleResize() {
      this.cWT.width = 0; // Set to 0 first so it doesn't mess up the container
      this.cWT.width = Math.floor(this.$refs.wtParent.offsetWidth);
      this.cWT.highest_data = Math.max(...this.wave_data);
      this.step = Math.round((this.wave_data.length / this.cWT.width)*2)/2; // Round to 0.5 -- the steps to take to render beginning to end
      this.drawWT();
    },
    drawWT() {
      this.cWT_ctx.clearRect(0, 0, this.cWT.width, this.cWT.height);
      var pct = this.progress / 100;

      for (var i = 0; i < this.cWT.width * pct; i += 2) {
        this.cWT_ctx.fillStyle = this.color; // c.fillStyle = "rgba(200,200,200,1)";
        this.cWT_ctx.fillRect(
          i, // x: Use every other one
          0, // y
          1, // width
          Math.abs( // Convert negative numbers into positive
            Math.round(
              (this.wave_data[i * this.step] * this.cWT.height) / this.cWT.highest_data // 127 is the max of the waveform
              //(this.wave_data[i * this.step] * this.cWT.height) / 127 // 127 is the max of the waveform
            )
          )
        );
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  }
};
</script>


<style scoped lang="stylus">
.waveTableBox
  position relative
  canvas
    position absolute
</style>
