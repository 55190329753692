import { ApiCalls } from "@/components/utils/api-calls";

import Times from "@/mixins/mixins.js";
import SrUtils from "@/mixins/srutils.js";
const DEBUG_LOG = false;


export default {
    mixins: [Times, SrUtils],
    data() {
        return {
            playlist: false,
            percentProgress: false,
            station_slug: false,
            message: {},
            timeoutTimer: false,
        };
    },
    methods: {
        async getQueue(station_slug) {
            if (!station_slug) { // On direct first load of /station/{station_slug}
                return;
            }

            this.last_update = Object.is(this.last_update, undefined) || this.last_update == null ? 0 : this.last_update;

            //console.log(`[${this.station_slug}] Last Update: ${this.last_update}`);

            //request throttling - reject requests < 1 sec apart
            if ((this.getLocalTime() - this.last_update) < 1) {
                console.log(`[${this.station_slug}] Throttling request - last_update:${this.last_update}`);
                setTimeout(() => { this.getQueue(this.station_slug); }, 1000);
                return;
            }

            this.last_update = this.getLocalTime();
            //console.log(`[${this.station_slug}] Last Update: ${this.last_update}`);

            this.station_slug = station_slug;
            if (DEBUG_LOG) {
                console.log(`[${this.station_slug}] Getting Queue`);
            }

            try {
                const playlist = await ApiCalls.station.StationQueue(this.station_slug);

                this.playlist = playlist;
                var np = this.playlist.now_playing;
                np.track_image = this.getTrackImage(np.image);

                this.server_time_offset = this.playlist.station.time - this.getLocalTime();

                //this.remain = np.time_played + np.seconds - np.time_server;
                this.remain = np.time_played + np.seconds - this.getLocalTime() - this.server_time_offset;

                var remain_date = Math.floor(Date.now() / 1000) + this.remain; // Milli to Sec + Remain
                for (var item of this.playlist.queue) {
                    item.when_play = this.sec_to_12hr(remain_date);
                    remain_date += item.seconds;
                    item.track_image = this.getTrackImage(item.image);
                }

                if (DEBUG_LOG) {
                    console.log(`[${this.station_slug}] last_update: ${this.last_update}`);
                    console.log(`[${this.station_slug}] time_played: ${np.time_played}`);
                    console.log(`[${this.station_slug}] seconds: ${np.seconds}`);
                    console.log(`[${this.station_slug}] time_server: ${np.time_server}`);
                    console.log(`[${this.station_slug}] offset: ${this.server_time_offset}`);
                }

                this.message = {};
                this.updateUi();
            }
            catch (e) {
                console.log(`[${this.station_slug}] queue load failed, retry in 5 sec...`);
                this.message.class = "error";
                this.message.text = "Please wait - reloading in 5 sec";
                setTimeout(() => { this.getQueue(this.station_slug); }, 5000);
            }

        },

        updateUi() {
            //update progress
            var np = this.playlist.now_playing;
            this.remain = np.time_played + np.seconds - this.getLocalTime() - this.server_time_offset;
            this.percentProgress = Math.min(
                100,
                100 - (this.remain / this.playlist.now_playing.seconds) * (100).toFixed(2)
            );

            //check for queue refresh
            if (this.remain <= 0) {
                //this.last_update = null; //force update.
                this.getQueue(this.station_slug);
                return;
            }

            setTimeout(this.updateUi, 333);
        },

        getLocalTime() {
            return Math.floor(Date.now() / 1000);
        },
    }
};