<template lang="pug">
article(v-if='playlist').playlist
  #now-playing
    .countdown
      img(:src='playlist.now_playing.track_image')
      div {{ sec_to_h(remain) }}
    div(style='width: 100%;')
      h3.track-title-main
        | {{ playlist.now_playing.name | trackTitleFilter }}
        LinkUserRequestBy(:user_id='playlist.now_playing.user_id' :user_name='playlist.now_playing.user_name')
        | &nbsp;
        ButtonUserFavorite(:track_id="playlist.now_playing.track_id")
      .progress-bar-container
        Progress(:now_playing='playlist.now_playing' :progress='percentProgress')
        .time
          div(:style="'position: absolute; left: '+percentProgress+'%;'") {{ sec_to_h(remain) }}
          div {{playlist.now_playing.seconds_h}}
  div.message(v-if="message.text" :class="message.class") {{ message.text }}
  // Coming up
  div.playlist
    table
      tbody
        tr(v-for='track in playlist.queue' :key='track.id')
          td
            img(:src='track.track_image').enlarge
          td
            | {{ track.name | trackTitleFilter }}
            LinkUserRequestBy(:user_id='track.user_id' :user_name='track.user_name')
            | &nbsp;
            ButtonUserFavorite(:track_id="track.track_id")
          td.tar {{ track.seconds_h }}
          td.tar {{ track.when_play }}

          //
            <td>
            <i class="fab fa-twitter"></i>&nbsp;
            <i class="fab fa-facebook"></i>
            </td>

article(v-else='')
  i.fa.fa-sync.fa-spin.fa-2x
</template>

<script>
import { store } from "@/store";
import Queue from "@/mixins/queue.js";
import Progress from "@/components/ui/Progress-Waveform.vue";
//import Progress from "@/components/ui/Progress-Simple.vue";
import ButtonUserFavorite from "@/components/ui/ButtonUserFavorite.vue";
import LinkUserRequestBy from "@/components/ui/LinkUserRequestBy.vue";

export default {
  mixins: [Queue],
  components: {
    Progress,
    ButtonUserFavorite,
    LinkUserRequestBy,
  },
  data() {
    return {
      STATE: store.state
    };
  },
  watch: {
    "STATE.station": {
      immediate: true,
      handler: function() {
        this.getQueue(this.STATE.station.station_slug);
      }
    }
  },
  methods: {}
};
</script>


<style scoped lang="stylus">
#now-playing
  display flex
  align-content center
  margin 10px 5px
  font-weight bold
  font-size 20px
  height 90px
  img
    height 90px

.countdown
  position relative
  margin-right 5px
  font-size 90%
  div
    position absolute
    bottom 0
    right 0
    text-shadow 0px 0px 10px #000, 0px 0px 10px #000, 0px 0px 20px #000, 0px 0px 20px #000

// Media Queries
@media screen and (max-width: 768px)
  h = 60px
  #now-playing
    font-size 15px
    height h
    img
      height h
</style>
