<template>
  <section>
    <div class="auth">
      <login />
      <span class="title" style="width:50px;padding-bottom:25px;"> - or - </span>
      <create-account />
    </div>
  </section>
</template>

<script>
import CreateAccount from "@/components/auth/create-account";
import Login from "@/components/auth/login";

export default {
  name: "authenticate",
  data() {
    return {};
  },
  components: {
    CreateAccount,
    Login
  },
  created() {},
  watch: {},
  computed: {},
  mounted() {},

  methods: {}
};
</script>

<style lang="stylus" src="@/assets/stylus/auth-styling.styl"/>
