<script>
import _ from "lodash";
import { ValidationMessages } from '@/consts/validation-messages'

// curly brace syntax
_.templateSettings.interpolate = /{{([\s\S]+?)}}/g;

export default {
  props: {
    validations: {
      required: true,
      type: Object
    }
  },

  computed: {
    errors() {
      if (!this.invalid) {
        return [];
      }

      return Object.keys(this.validations.$params).reduce(
        (errors, validator) => {
          if (!this.validations[validator]) {
            const compiled = _.template(ValidationMessages[validator]);
            errors.push(compiled(this.validations.$params[validator]));
          }

          return errors;
        },
        []
      );
    },

    invalid() {
      return this.validations.$dirty && this.validations.$invalid;
    }
  },

  render() {
    return this.$scopedSlots.default({
      errors: this.errors,
      invalid: this.invalid
    });
  }
};
</script>
