<template>
  <modal
    name="modal-notice"
    transition="nice-modal-fade"
    :min-width="200"
    :min-height="200"
    :width="params.width || 600"
    :height="params.height ||'auto'"
    :delay="100"
    :adaptive="true"
    @before-open="beforeOpened"
    @before-close="beforeClosed"
  >
    <div class="modal-notice-content" v-html="params.text || 'Test'"></div>
  </modal>
</template>
<script>
export default {
  name: "modal-notice",
  data() {
    return {
      params: {}
    };
  },
  methods: {
    beforeOpened(event) {
      this.params = event.params || {};
      this.$emit("before-opened", event);
    },
    beforeClosed(event) {
      this.params = {};
      this.$emit("before-closed", event);
    }
  }
};
</script>

<style lang="stylus">
.modal-notice-content {
  background-color: #000;
  padding: 20px;
  font-size: 11px;
  letter-spacing: 0.05em;
  line-height: 1.2em;

  p {
    &:not(:first-child) {
      margin-top: 15px;
    }
  }
}
</style>