<template lang="pug">
  div.page-container
    div.tabs
      div(
        v-for='(name, key) in lists' :key=`key`
        :class="{'selected': current.list === key}"
        @click="selectList(key)"
      )
        | {{ name }}
    div.tabs.time-span
      div(
        v-for='(name, key) in timeSpans' :key=`key`
        :class="{'selected': current.timeSpan === key}"
        @click="selectTimeSpan(key)"
      )
        | {{ name }}
    LoadingSpinner(v-if="loading")
    div.table-container(v-else-if="data.length == 0")
      | Sorry, no stats to show
    div.table-container(v-else)
      table.track-list.playlist
        // Tracks
        tbody(v-if="current.list != 'requester'")
          tr
            th
            th.tal Title
            th Time
            th Total
            th
          tr(v-for='track in data' :key='track.track_id')
            td
              img.enlarge(:src='getTrackImage(track.image)')
            td
              | {{ track.name | trackTitleFilter }}
            td.tar {{ track.seconds_h }}
            td.tac {{ track.count }}
            td.tar
              ButtonUserFavorite(:track_id="track.track_id")
        // Requester (just name and count)
        tbody(v-if="current.list == 'requester'")
          tr
            th.tal(style="width: 50%") User Name
            th.tar Total Requests
          tr(v-for='user in data' :key='user.user_id')
            td.hover(@click="STORE.navigate(`/user/favorites/${user.user_id}`)") {{ user.user_name }}
            td.tar {{ user.count }}
</template>

<script>
import { store } from "@/store";
import { ApiCalls } from "@/components/utils/api-calls";
import LoadingSpinner from "@/components/ui/LoadingSpinner.vue";
import ButtonUserFavorite from "@/components/ui/ButtonUserFavorite.vue";
import LinkUserRequestBy from "@/components/ui/LinkUserRequestBy.vue";
import SrUtils from "@/mixins/srutils.js";

export default {
  mixins: [SrUtils],
  components: {
    LoadingSpinner,
    ButtonUserFavorite,
    LinkUserRequestBy,
  },
  data() {
    return {
      STORE: store,
      current: {
        list: "requested",
        timeSpan: "today",
      },
      lists: {
        "requested": "Top Requested",
        "requester": "Top Requester",
        "favorited": "Top Favorited",
      },
      timeSpans: {
        "today": "Today",
        "week": "Week",
        "month": "Month",
        "6_months": "6 Months",
        "year": "Year",
        "all_time": "All Time",
      },
      data: [],
      loading: true,
    };
  },
  mounted() {
    this.getTopTracks()
  },
  methods: {
    selectList(key) {
      this.current.list = key;
      this.getTopTracks();
    },
    selectTimeSpan(key) {
      this.current.timeSpan = key;
      this.getTopTracks();
    },
    async getTopTracks() {
      this.loading = true;
      this.data = [];
      this.data = await ApiCalls.stats.GetTopTracks(this.current.list, this.current.timeSpan);
      this.loading = false;
    }
  },

};
</script>

<style lang="stylus" scoped>
@import '~/src/assets/stylus/variables.styl';

.tabs
  display flex
  margin 0 10px
  &.time-span
    background-color rgba(255,255,255,0.15)
    padding 5px
    border-radius 0 0 10px 10px
    .selected
      border-radius 10px
  div
    display flex
    flex-grow 1
    height 40px
    justify-content center
    align-items center
    // border 1px solid #fff8
    border-top 0
    border-bottom 0
    cursor pointer
    transition all 200ms ease-in-out
    font-weight bold
    &.selected
      background-color rgba(255,255,255,0.15)
      shadow-blue()
      border-radius 10px 10px 0 0

.table-container
  margin 10px
</style>