<template>
  <div>
    <Waveform :wave_data="now_playing.waveform" height="30" />
    <Waveform
      :wave_data="now_playing.waveform"
      height="30"
      color="#08f"
      :progress="progress"
    />
  </div>
</template>

<script>
import Waveform from "@/components/ui/Waveform.vue";

export default {
  components: {
    Waveform
  },
  props: {
    now_playing: {},
    progress: {}
    },
  data() {
    return {};
  },
  watch: {},
  methods: {}
};
</script>