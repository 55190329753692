export default {
    methods:{
        setActionHandlers: ({handlerPlay, handlerPause}) => {
            if (!('mediaSession' in navigator)) return;

            navigator.mediaSession.setActionHandler('play', handlerPlay);
            navigator.mediaSession.setActionHandler('pause', handlerPause);
            // navigator.mediaSession.setActionHandler('seekbackward', function() { /* Code excerpted. */ });
            // navigator.mediaSession.setActionHandler('seekforward', function() { /* Code excerpted. */ });
            // navigator.mediaSession.setActionHandler('previoustrack', function() { /* Code excerpted. */ });
            // navigator.mediaSession.setActionHandler('nexttrack', function() { /* Code excerpted. */ });
        },

        setMetadata: ({trackName, station, image}) => {
            if (!('mediaSession' in navigator)) return;

            // // eslint-disable-next-line
            // navigator.mediaSession.metadata = navigator.mediaSession.metadata || new MediaMetadata({artist:'Subsonic Radio'});
            // navigator.mediaSession.metadata.title = trackName || "";
            // navigator.mediaSession.metadata.album = station || "";
            // navigator.mediaSession.metadata.artwork = [{ src: image ||  }];

            // eslint-disable-next-line
            navigator.mediaSession.metadata = new MediaMetadata({
                title: trackName  || "",
                artist: 'Subsonic Radio',
                album: station,
                artwork: [{ src: image }]
            });
        },

        setPlaybackStatePlaying:()=>{
            if (!('mediaSession' in navigator)) return;
            navigator.mediaSession.playbackState = "playing";
        },
        setPlaybackStatePaused:()=>{
            if (!('mediaSession' in navigator)) return;
            navigator.mediaSession.playbackState = "paused";
        },

        setPositionState: ({duration, position}) => {
            if (!('mediaSession' in navigator) || !navigator.mediaSession.setPositionState) return;
            if(!duration || !position) return;

            try{
                navigator.mediaSession.setPositionState({
                    duration: duration,
                    position: position});
                
            }
            // eslint-disable-next-line
            catch{}
        }
    }
};