import { render, staticRenderFns } from "./sr-faq-template.vue?vue&type=template&id=689e8def&scoped=true&ref=faqtemplate&"
import script from "./sr-faq-template.vue?vue&type=script&lang=js&"
export * from "./sr-faq-template.vue?vue&type=script&lang=js&"
import style0 from "@/assets/stylus/markdown.styl?vue&type=style&index=0&id=689e8def&lang=stylus&scoped=true&"
import style1 from "./sr-faq-template.vue?vue&type=style&index=1&id=689e8def&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "689e8def",
  null
  
)

export default component.exports