<template>
  <div class="md-layout md-layout-item md-size-100 md-alignment-top-center " style="height:fit-content;padding-top:5px;">
    <md-card class="sr-container ">
      <md-card-content>
        <div class="item-box md-layout md-alignment-center-center">
            <div class="md-layout-item md-size-25">
                <img  id="inline-image" src="/img/whats-new/iphone-ECqF.png">
            </div>
          <div class="md-layout-item md-size-75 left-padded">
            <div class="md-display-1 main-title">Welcome to Subsonic Radio 3.0!</div>
            <p>We've been a bit busy around here!  You've already seen the cosmetic differences, but the look and feel of the site aren't the only things that have changed!</p>
            <p>Here's a quick rundown of some of the new things we've added:</p>
            <ul class="md-layout md-alignment-center-left">
                <li class="md-layout md-layout-item md-size-100">
                    <md-icon class="md-layout-item md-size-5">verified</md-icon>
                    <span class="md-layout-item md-size-95">All stations are now requestable!</span>
                </li>
                <li class="md-layout md-layout-item md-size-100">
                    <md-icon class="md-layout-item md-size-5">verified</md-icon>
                    <span class="md-layout-item md-size-95">More tracks</span>
                </li>
                <li class="md-layout md-layout-item md-size-100">
                    <md-icon class="md-layout-item md-size-5">verified</md-icon>
                    <span class="md-layout-item md-size-95">Track images</span>
                </li>                
                <li class="md-layout md-layout-item md-size-100">
                    <md-icon class="md-layout-item md-size-5">verified</md-icon>
                    <span class="md-layout-item md-size-95">New login system (more on that below)</span>
                </li>                
                <li class="md-layout md-layout-item md-size-100">
                    <md-icon class="md-layout-item md-size-5">verified</md-icon>
                    <span class="md-layout-item md-size-95">Holiday tracks only play during the park holidays</span>
                    <span class="md-layout-item md-size-5"></span>
                    <span class="md-layout-item md-size-95">(You can still request them any time!)</span>
                </li>
                <li class="md-layout md-layout-item md-size-100">
                    <md-icon class="md-layout-item md-size-5">verified</md-icon>
                    <span class="md-layout-item md-size-95">Web site is an "installable" Progressive Web App.</span>
                    <span class="md-layout-item md-size-5"></span>
                    <span class="md-layout-item md-size-95">Add a shortcut to SR on your computer!</span>
                </li>
            </ul>            
            <p>And we have some more in the works, here's a few that might show up in the future:</p>
            <ul class="md-layout md-alignment-center-left">
                <li class="md-layout md-layout-item md-size-100">
                    <md-icon class="md-layout-item md-size-5">construction</md-icon>
                    <span class="md-layout-item md-size-95">Login and make requests from the mobile apps</span>
                </li>
                <li class="md-layout md-layout-item md-size-100">
                    <md-icon class="md-layout-item md-size-5">construction</md-icon>
                    <span class="md-layout-item md-size-95">Discord login integration</span>
                </li>
                <li class="md-layout md-layout-item md-size-100">
                    <md-icon class="md-layout-item md-size-5">construction</md-icon>
                    <span class="md-layout-item md-size-95">Favorite tracks</span>
                </li>
                <li class="md-layout md-layout-item md-size-100">
                    <md-icon class="md-layout-item md-size-5">construction</md-icon>
                    <span class="md-layout-item md-size-95">Upcoming track notifications</span>
                </li>
            </ul>
          </div>
          <div class="md-layout-item md-size-100" style="padding-top:20px; margin-bottom:10px;border-bottom: 1px solid #ffffff80">&nbsp;</div>
          <div class="md-layout-item md-size-75 right-padded">
            <div class="md-display-1 sub-title">That's awesome and all, but I can't log in!</div>
            <p>One of the problems with making the new login system more secure is that we couldn't transfer over the old passwords.  You'll need to take the following steps the first time you log in:</p>
            <ul class="md-layout md-alignment-center-left">
                <li class="md-layout md-layout-item md-size-100">
                    <md-icon class="md-layout-item md-size-5">keyboard_arrow_right</md-icon>
                    <span class="md-layout-item md-size-95">Go to the login page. 
                        <i>(<i data-v-6eb444ee="" class="fas fa-sign-in-alt"></i> icon in upper right)</i>
                    </span>
                </li>                
                <li class="md-layout md-layout-item md-size-100">
                    <md-icon class="md-layout-item md-size-5">keyboard_arrow_right</md-icon>
                    <span class="md-layout-item md-size-95">On the Login panel, click <i> "I Forgot My Password"</i></span>
                </li>
                <li class="md-layout md-layout-item md-size-100">
                    <md-icon class="md-layout-item md-size-5">keyboard_arrow_right</md-icon>
                    <span class="md-layout-item md-size-95">Enter your username or email address and click <i> "SEND RESET EMAIL"</i></span>
                </li>
                <li class="md-layout md-layout-item md-size-100">
                    <md-icon class="md-layout-item md-size-5">keyboard_arrow_right</md-icon>
                    <span class="md-layout-item md-size-95">You should recieve an email shortly that will have a link you can use to reset your</span>
                    <span class="md-layout-item md-size-5"></span>
                    <span class="md-layout-item md-size-95">password on the site.</span>
                </li>
            </ul>
            <p>If you experience any issues, please contact a staff member on our Discord chat <a href="https://discord.com/channels/398563329364197387/398563329364197389" target="_blank">here</a> (Preffered method), or via the <a @click="STORE.navigate('/support')">Support / Feedback page</a>.</p>
            </div>
            <div class="md-layout-item md-size-25">
                <img  id="inline-image" src="/img/whats-new/login-screen.jpg">
            </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import { store } from "@/store";

export default {
  data() {
    return { STORE: store };
  },
  methods: {},
};
</script>

<style lang="stylus" src="@/assets/stylus/material-design.styl"/>
<style lang="stylus" scoped>

#inline-image {
  border-radius 5px;
  box-shadow 2px 2px 2px #00000080
}

.sub-title{
    font-size :200%
}

.left-padded{
    padding-left: 20px;
}
.right-padded{
    padding-right: 20px;
}

.md-card.md-theme-default {
  background-color: rgba(0, 0, 0, 0.25);
}

.sr-container {
  width: 90%;
  border-radius: 10px;
  box-shadow: 2px 2px 7px #000;
  margin-top: 10px;
  margin-left: 10px;
  padding-bottom: 5px;
  margin-bottom: 25px;
  .md-display-1 {
    text-shadow 3px 3px 4px #00000080
    font-variant: small-caps;
  }
  p {
      padding-top:15px;
  }
  ul {
      padding-top:5px;
      li{
          padding-left:25px;
          .md-icon{
          }
          div{
              display: inline-block;
          }
          span{
              padding-left: 10px;
              vertical-align :middle
          }
      }
  }
}
</style>
