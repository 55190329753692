<template lang="pug">
    article#search-container
      i.fas.fa-times-circle.fa-2x(@click="parentSearchClose")
      .search-container-2
        .results-header
          h3
            i.fa.fa-search
            | Search results for:
            | {{ this.$parent.search_text }}
          h2(v-if="info") {{ info.n_start }}
            | -
            | {{ info.n_end }}
            | of
            | {{ info.n_total_found }}
            | (
            | {{ info.total_tracks }}
            | in
            | {{STATE.station.station_name}}
            | )
          // Pagination
          Pagination(
            v-if="info"
            :totalItems="info.n_total_found"
            :itemsPerPage="50"
            :currentPage="page+1"
            @page-changed="handlePageChanged"
          )
        #search-results.results
          table(v-if="tracks")
            tr(v-for="track in tracks", :key="track.track_id", :class="{ nope: !track.can_request}")
              td
                img(style="height: 20px", src="/icons/placeholder_icon.png")
              td.search-track-title(@click.self.stop="requestTrack($event, track.track_id, track.can_request)") {{ track.name }}
              td.tar {{ track.seconds_h }}
              td.tar {{ track.when_play }}
              td
                ButtonUserFavorite(:track_id="track.track_id")
          .tac(v-else)
            i.fa-solid.fa-spin.fa-rotate.fa-2x
</template>

<script>
import axios from "axios";
import _ from "lodash";

import { store, URLS } from "@/store";
import ButtonUserFavorite from "@/components/ui/ButtonUserFavorite.vue";
import Pagination from "@/components/ui/PaginationWithPageNumbers.vue";

export default {
  props: ["search_text", "parentSearchClose"],
  components: {
    ButtonUserFavorite,
    Pagination
  },
  data() {
    return {
      STATE: store.state,
      info: false,
      tracks: [],
      page: 0,
      more: true,
      request_target: false,
      old_title: false,
      redirecting: false,
    };
  },
  watch: {
    "STATE.station": {
      immediate: true,
      handler: function () {
        //this.getCurrentTrack();
      }
    },
    search_text: _.debounce(function () {
      this.page = 0;
      this.searchTracks();
    }, 500)
  },
  created: function () { },
  destroyed: function () {
    document.removeEventListener("click", this.windowClick);
  },
  mounted() {
    document.addEventListener("click", this.windowClick, { passive: true });
    // load the first list
    this.searchTracks();
  },

  /* -- METHODS --  */
  methods: {
    handlePageChanged(pageNumber) {
      this.page = pageNumber - 1;
      this.searchTracks();
    },
    // Check if the requested click is on the same target, if not, remove it
    windowClick(evt) {
      //evt.stopPropagation();

      if (this.request_target && this.request_target != evt.target) {
        this.request_target.innerHTML = this.old_title;
        this.request_target = false;
      }
    },
    async requestTrack(evt, track_id, can_request) {
      var isValid = await this.$auth.isValidAuth();
      if (!isValid) {
        if (!this.redirecting) {
          await this.$auth.PerformLoginAndRedirect();
          this.redirecting = true;
        }
        return;
      }
      const userInfo = this.$auth.getPayload();
      if (!can_request) return;
      // Clicking a second time will request
      if (this.request_target == evt.target) {
        //evt.target.innerHTML = this.old_title;
        // Do the request
        const url = `${URLS.API}/station/${this.STATE.station.station_slug}/request/${track_id}`;
        const { data } = await axios.get(url, { params: { user_id: userInfo.user_id, user_name: userInfo.username } });
        switch (data) {
          case 'OK': evt.target.innerHTML = "Queued!"; break;
          case 'recent': evt.target.innerHTML = "This recently played"; break;
          case 'in_queue': evt.target.innerHTML = "This is in the queue!"; break;
          case 'user_queue_exceeded': evt.target.innerHTML = "You reached your queue limit. Try again after one of your tracks have played."; break;
        }
        setTimeout(() => {
          evt.target.innerHTML = this.old_title;
          evt.target.parentNode.className = "nope";
        }, 2000);
        return;
      }
      // Click on another row
      if (this.request_target && this.request_target != evt.target) {
        this.request_target.innerHTML = this.old_title;
        this.request_target = false;
      }

      this.request_target = evt.target;
      this.old_title = evt.target.innerHTML;
      evt.target.innerHTML = "Click again to request!";
    },
    async searchTracks() {
      const url = `${URLS.API}/station/${this.STATE.station.station_slug}/tracks`;

      // Reset
      this.tracks = false;
      document.querySelector("#search-results").scrollTop = 0;

      // The count is limited on the backend by 50
      const { data } = await axios.post(url, { search: this.$parent.search_text, page: this.page });
      this.info = data.info;
      this.info.total_tracks = data.station.total_tracks;
      this.tracks = data.tracks;
    }
  }
};
</script>


<style scoped lang="stylus">
.fa-search
  margin-right 5px
.fa-spin
  margin-top 20px
  font-size 300%
  text-shadow #000 1px 1px 10px

#search-container
  background rgba(255, 255, 255, 0.15)
  border outset 3px rgba(255, 255, 255, 0.5)
  border-radius 10px
  box-shadow 2px 2px 10px #000
  flex-grow 1
  margin 0 5px
  position relative

  > i
    cursor pointer
    position absolute
    right 2px
    top 2px
    z-index 999

    &:hover
      text-shadow 0 0 5px #fff

  .search-container-2
    bottom 0
    display flex
    flex-direction column
    left 0
    position absolute
    right 0
    top 0

  .results-header
    background rgba(0, 0, 0, 0.15)
    background rgba(255, 255, 255, 0.15)
    // border-bottom 1px dotted rgba(255, 255, 255, 0.5)
    box-shadow 0 2px 5px #000
    padding 5px

  .results
    flex-grow 1
    overflow auto

  tr:nth-child(even)
    background rgba(0, 0, 0, 0.1)

  td
    padding 2px
    vertical-align middle

    &:first-child
      padding-right 0

    &:nth-child(2)
      padding-left 0
      width 99%

    &:last-child
      white-space nowrap

      i
        margin 0 2px

  // Track hover
  tr:not(.nope) td:nth-child(2):hover
    // text-shadow #75B4ff 0 0 10px, #75B4ff 0 0 20px
    box-shadow inset 0 0 15px #75B4ff
    cursor pointer
    text-shadow 0 0 5px #fff
    // box-shadow inset 0 0 5px rgba(255, 255, 255, 0.5), inset 0 0 15px rgba(255, 255, 255, 0.5)
    // box-shadow inset 0 0 20px rgba(255, 255, 255, 0.5)

h3
  font-size 130%

h2
  font-size 110%

h3, h2
  font-weight bold
  margin-bottom 5px
  text-align center

.nope
  color rgba(255, 255, 255, 0.25)
  cursor not-allowed
</style>
