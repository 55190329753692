<template>
  <div class="md-layout md-layout-item md-size-100 md-alignment-top-center " style="height:fit-content;">
    <div class="md-layout md-layout-item md-size-100 md-alignment-top-center " style="height:fit-content;">
      <md-card class="sr-container-note" v-if="!status.submitted">
        <div class="md-title">Please Note</div>
        <md-card-content>
          <div class="hr">&nbsp;</div>
          <p>We are currently in process of updating the Windows, and macOS applications to catch up with our site update.</p>
          <div class="hr">&nbsp;</div>
          <p>As always, stop by over on <a @click="STORE.navigate('/chat')">Discord</a> for a faster response, to say hi, or even ask our bot SRINI what's playing!</p>
        </md-card-content>
      </md-card>
    </div>
    <h1 class="md-headline md-layout-item md-size-100">Need help or have some feedback?</h1>
    <md-card class="sr-container " v-if="!status.submitted">
      <md-card-header>
        <div class="md-title">Support / Feedback</div>
      </md-card-header>

      <md-card-content>
        <form novalidate @submit.prevent="validate">
          <md-field class="md-layout-item md-size-100" :class="getValidationClass('form.name')">
            <label>Name</label>
            <md-input v-model="form.name" :md-counter="getMaxLength('form.name')" />
            <span class="md-error" v-if="!this.$get(this.$v.form, 'name.required')">Name is required</span>
            <span class="md-error" v-else-if="!this.$get(this.$v.form, 'name.maxLength')">Name must be {{getMaxLength('form.name')}} characters or less</span>
          </md-field>

          <md-field class="md-layout-item md-size-100" :class="getValidationClass('form.username')">
            <label>Username</label>
            <md-input v-model="form.username" :md-counter="getMaxLength('form.username')" />
            <span class="md-error" v-if="!this.$get(this.$v.form, 'username.maxLength')">Username must be {{getMaxLength('form.username')}} characters or less</span>
          </md-field>

          <md-field class="md-layout-item md-size-100" :class="getValidationClass('form.email')">
            <label>Email</label>
            <md-input v-model="form.email" :md-counter="getMaxLength('form.email')" />
            <span class="md-error" v-if="!this.$get(this.$v.form, 'email.required')">Email is required</span>
            <span class="md-error" v-else-if="!this.$get(this.$v.form, 'email.maxLength')">Email must be {{getMaxLength('form.email')}} characters or less</span>
            <span class="md-error" v-else-if="!this.$get(this.$v.form, 'email.email')">Not a valid email address</span>
          </md-field>

          <md-field class="md-layout-item md-size-100" :class="getValidationClass('form.issue')">
            <label for="issues">Issue</label>
            <md-select v-model="form.issue" name="issues" id="issues" md-dense>
              <md-option v-for="item in reasons" :key="item.reason" :value="item.reason">{{item.reason}}</md-option>
            </md-select>
            <span class="md-error" v-if="!this.$get(this.$v.form, 'issue.required')">Issue is required</span>
          </md-field>

          <md-field class="md-layout-item md-size-100" :class="getValidationClass('form.description')">
            <label>Description / Comment</label>
            <md-textarea v-model="form.description" :md-counter="getMaxLength('form.description')" />
            <span class="md-error" v-if="!this.$get(this.$v.form, 'description.required')">Description / Comment is required</span>
            <span class="md-error" v-else-if="!this.$get(this.$v.form, 'description.maxLength')">Description / Comment must be {{getMaxLength('form.description')}} characters or less</span>
          </md-field>

          <vue-recaptcha ref="recaptcha" size="invisible" theme="dark" :loadRecaptchaScript="true" sitekey="6LdkWc8bAAAAAE6KwPGiGvs5iWkAEHmK74Mtw2FW" @verify="onVerify" @expired="onExpired">
          </vue-recaptcha>
        </form>
      </md-card-content>

      <md-card-actions>
        <md-button class="md-primary" @click="validate" :disabled="status.submitting">Send Message</md-button>
      </md-card-actions>
    </md-card>

    <md-card class="sr-container " v-if="status.submitted">
      <md-card-header>
        <div class="md-title">Support / Feedback</div>
      </md-card-header>

      <md-card-content>
        <p>Your information has been sent, thanks for your input!</p>
      </md-card-content>

      <md-card-actions>
        <md-button class="md-primary" @click="validate" v-if="!status.submitted" :disabled="status.submitting">Send Message</md-button>
      </md-card-actions>
    </md-card>

  </div>
</template>

<script>
//https://itnext.io/how-to-use-google-recaptcha-with-vuejs-7756244400da

import supportreasons from './support-reason.json'
import { ApiCalls } from "@/components/utils/api-calls";
import { store } from "@/store";

import VueRecaptcha from 'vue-recaptcha';
import SrValidationHelper from "@/mixins/srvalidationhelper.js";
import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  components: { VueRecaptcha },

  mixins: [
    SrValidationHelper
  ],

  data() {
    return {
      STORE: store,
      reasons: supportreasons,
      form: {
        name: '',
        username: '',
        email: '',
        issue: "",
        description: "",
      },
      status: {
        submitting: false,
        submitted: false
      }
    }
  },

  validations: {
    form: {
      name: {
        required,
        maxLength: maxLength(100)
      },
      username: {
        maxLength: maxLength(45)
      },
      email: {
        required,
        email,
        maxLength: maxLength(45)
      },
      issue: {
        required
      },
      description: {
        required,
        maxLength: maxLength(2000)
      },
    }
  },

  mounted() {
    this.$v.form.$reset();

    if (!this.$auth.isValidAuth) return;
    this.$data.form.username = this.$auth.getUsername();
    this.$data.form.email = this.$auth.getEmail();

    this.$v.form.$reset();

  },

  methods: {
    onVerify: async function (response) {
      this.$data.submitting = true;
      this.$refs.recaptcha.reset();
      await ApiCalls.system.SendSupportEmail(this.$data.form, response);
      this.$data.status.submitted = true;
    },
    onExpired: function () {
      console.log('Expired')
    },
    validate() {

      this.$v.form.$touch();

      if (!this.$v.form.$invalid) {
        console.log(this.$refs.recaptcha)
        this.$refs.recaptcha.execute();
        //console.log("Valid")
      }
    },

  },
}
</script>

<style lang="stylus" src="@/assets/stylus/material-design.styl"/>
<style lang="stylus" scoped>
.md-card.md-theme-default {
  background-color: rgba(0, 0, 0, 0.25);
}

.md-field textarea {
  height: 200px;
}

h1 {
  padding-top: 20px;
  height: fit-content;
}

.sr-container, .sr-container-note {
  width: 400px;
  border-radius: 10px;
  box-shadow: 2px 2px 7px #000;
  margin-top: 10px;
  margin-left: 10px;
  padding-bottom: 5px;
  margin-bottom: 25px;
}

hr-border = alpha(#AAA, 0.15);
.hr
  width 100%;
  height 0; /* Firefox... */
  box-shadow 0 0 20px 1px hr-border, 0 0 1px 1px hr-border, 0 0 5px 1px hr-border, 0 0 3px 1px hr-border, 0 0 1px 1px hr-border;
  margin-bottom 3px;

.sr-container-note
  width: 1000px;
  text-shadow: 3px 3px 4px rgb(0 0 0 0.5);
  margin-top: 25px;
  margin-bottom: 0px;
  background-color: rgba(126, 38, 141, 0.25) !important;

  .md-title
    padding-top: 10px;
    padding-bottom: 10px;
    font-variant: small-caps;
  .md-card-content
    padding-top: 0px;
    padding-bottom: 0px;
    .hr,p
      width:90%
      margin-left: 5%
      margin-right: 5%
    p
      padding-top: 12px;  
      padding-bottom: 12px;  
      text-align: center


.reason-selector {
  .label {
    break-before: always;
    margin-left: 10px;
    width: auto;
    font-family: 'Montserrat';
    font-size: smaller;
    font-variant: small-caps;
  }
}
</style>
