<template ref="faqtemplate">
    <div id="top" class="md-layout md-layout-item md-size-100 md-alignment-top-center " style="height:fit-content;padding-top:5px;">
        <md-card v-show="!loaded" class="sr-container ">
            <md-card-content>
                <div class="item-box md-layout md-alignment-center-center">
                    <div class="md-layout-item md-size-100">
                        <div class="md-display-1 main-title md-layout md-alignment-center-center">Subsonic Radio FAQ</div>
                        <div class="hr">&nbsp;</div>
                        <div class="main-title md-layout md-alignment-center-center">Loading</div>
                        <div class="main-title md-layout md-alignment-center-center" style="margin-top:20px">
                            <md-progress-spinner :md-diameter="50" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
                        </div>
                    </div>
                </div>
            </md-card-content>
        </md-card>
        <md-card v-show="loaded" class="sr-container ">
            <md-card-content>
                <div class="item-box md-layout md-alignment-center-center">
                    <div class="md-layout-item md-size-100">
                        <div class="md-display-1 main-title md-layout md-alignment-center-center">Subsonic Radio FAQ</div>
                        <div class="hr">&nbsp;</div>
                        <ul class="md-layout md-alignment-center-center">
                            <li class="md-layout md-layout-item md-small-size-100 md-size-50" v-for="section in faqData.sections" v-bind:key="section.section_title">
                                <a :href="`#${kebaber(section.section_title)}`">
                                    <md-icon class="icon-spacing md-layout-item">forum</md-icon>
                                    <span class="md-layout-item md-size-95 md-subheading">{{section.section_title}}</span>
                                </a>
                            </li>
                        </ul>
                    </div>

                </div>
            </md-card-content>
        </md-card>
        <md-card v-show="loaded" class="sr-container section" v-for="section in faqData.sections" v-bind:key="section.section_title">
            <md-card-content>
                <div class="item-box md-layout md-alignment-center-center">
                    <div class="md-layout-item md-size-100">
                        <div class="section-title md-layout-item md-size-100">
                            <div class="section-title-left">&nbsp;</div>
                            <div class="section-title-center md-title main-title md-layout md-alignment-center-center">
                                <div :id="kebaber(section.section_title)">{{section.section_title}}</div>
                                <!-- <div>
                                <md-button class="md-icon-button" :href="`#top`">
                                <md-icon>arrow_upward</md-icon>
                                </md-button>
                            </div> -->
                            </div>
                            <div class="section-title-right">
                                <div>
                                    <md-button class="md-icon-button" :href="`#top`">
                                        <md-icon>arrow_upward</md-icon>
                                    </md-button>
                                </div>
                            </div>
                        </div>
                        <div class="hr">&nbsp;</div>
                        <ul class="md-layout md-alignment-center-left">
                            <li class="md-layout md-layout-item md-size-100" v-for="item in section.items" v-bind:key="item.item_id">
                                <div class="md-layout-item md-size-100 question">
                                    <md-icon class="question-icon">live_help</md-icon>
                                    <div class="question-text md-body-2" v-html="item.question_text" />
                                </div>
                                <div class="md-layout-item md-size-100 answer">
                                    <div class="answer-spacer">&nbsp;</div>
                                    <div :ref="item.item_id" class="md-layout-item md-size-95 markdown" />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </md-card-content>
        </md-card>
    </div>
</template>

<script>
export default {
  props: {
    "faq-data": Object,
  },
  data() {
    return {
      rendercache: [],
      loaded: false
    };
  },
  computed: {

  },
  methods: {

    kebaber(s) {
      return s.replace(/([a-z])([A-Z])/g, "$1-$2")
        .replace(/[^A-Za-z0-9\s_]/g, '')
        .replace(/[\s_]+/g, '-')
        .toLowerCase();
    },
    processItemMarkup(item, scope) {

      //console.log(kebaber(item.q));
      const html = this.$marked(item.answer_text);
      var el = document.createElement("html");
      el.innerHTML = html;
      el = el.getElementsByTagName("body")[0];

      for (var e of el.getElementsByTagName("*")) {
        e.setAttribute(scope, "");
      }

      const target = this.$get(this, `$refs.${item.item_id}[0]`);
      if (target) target.innerHTML = el.innerHTML;
    },

    findNodeScope(obj) {
      const attr = this.$get(obj, 'attributes');
      if (!attr) return;
      var node = Object.values(attr).find(d => d.nodeName.startsWith("data-v"));
      return node.nodeName;
    }
  },
  updated() {
    const scope = this.findNodeScope(this.$el);
    const sections = this.$get(this, 'faqData.sections');
    const items = sections.flatMap(i => i.items);

    //console.log(items);
    items.forEach(i => this.processItemMarkup(i, scope));
    this.loaded = true;
  },
  async mounted() {
  },

};
</script>

<style lang="stylus" scoped src="@/assets/stylus/markdown.styl"/>
<style lang="stylus" scoped>
#inline-image
  border-radius 5px
  box-shadow 2px 2px 2px #00000080

.sub-title
  font-size 200%

.left-padded
  padding-left 20px

.right-padded
  padding-right 20px

.md-card.md-theme-default
  background-color rgba(0, 0, 0, 0.25)

.hr
  margin-bottom 10px
  margin-top 20px

  & + *
    margin-top 35px

.section
  li
    margin-top 20px

    &:first-child
      margin-top 0

.section-title
  display flex
  flex-wrap nowrap
  justify-content space-between

.section-title-left, .section-title-center, .section-title-right
  align-self center

.section-title-left, .section-title-right
  width 55px

.sr-container
  border-radius 10px
  box-shadow 2px 2px 7px #000
  margin-bottom 25px
  margin-left 10px
  margin-top 10px
  padding-bottom 5px
  width 90%

  .md-display-1
    font-variant small-caps
    text-shadow 3px 3px 4px #00000080

  p
    padding-top 5px

  ul
    padding-top 5px

    li
      padding-left 25px

      .md-body-2
        font-weight 700

      .md-body-1
        font-weight 300

      .md-icon, div
        display inline-block

      .question
        align-content flex-start
        align-items flex-start
        display flex
        justify-content flex-start

        .question-icon
          align-content top
          align-self flex-start
          margin-right 5px
          margin-top 0
          order 1

        .question-text
          align-self flex-start
          flex-grow 1
          order 2

      .answer-spacer
        margin 0
        margin-right 5px
        width 24px

      span
        padding-left 10px
        vertical-align middle
</style>
