<template>
    <error-list :validations="validations">
      <div class="field-group" slot-scope="{ errors, invalid }">
        <div class="field-label">
          <label class="name">{{fieldname}}:</label>
          <div class="description"></div>
        </div>
        <div class="input">
          <input
            v-model="localValue"
            :class="{ 'invalid': invalid }"
            :autocomplete="autocomplete"
            :id="fieldid"
            :type="fieldtype"
          />
        </div>
        <div class="error" :class="{ 'invalid': invalid }">
          <span v-for="error in errors" v-bind:key="error">{{ error }}</span>
        </div>
      </div>
    </error-list>
</template>

<script>
import ErrorList from "@/components/validation/error-list";

export default {
  components: {
    ErrorList
  },
  props: {
    validations: {
      required: true,
      type: Object
    },
    autocomplete: {
      required: false,
      type: String
    },
    fieldname: {
      required: true,
      type: String
    },
    fieldid: {
      required: true,
      type: String
    },
    fieldtype: {
      required: true,
      type: String
    },
    value: {
      required: true,
      //type: Object
    }
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit("input", localValue);
      }
    }
  }
};
</script>
