<template lang="pug">
  .header-menu
    | {{ this.getUser() || 'User'}} &nbsp;
    i.fa-solid.fa-user
    .header-menu-hover
        .link.hover.divider(v-if="!STATE.isAuthed" @click="STORE.navigate('/login')")
          i.fa-solid.fa-right-to-bracket
          | Login

        .link.hover(@click="toggleExpertTitles")
          i.fa-eye(:class="isUsingExpertTitles ? 'fa-solid' : 'fa-regular'")
          | Use Expert Titles

        template(v-if="STATE.isAuthed")
          .link.hover.divider(@click="STORE.navigate('/user/favorites')")
            i.fa-solid.fa-star
            | Favorite Tracks

          .link(@click="STORE.navigate('/admin')" v-if='isAdmin()')
            i.fa-solid.fa-screwdriver-wrench
            | Admin

          .link.hover.divider(@click="STORE.navigate('/account-settings')")
            i.fa-solid.fa-gear
            | Account Settings
          .link.hover(@click='logout')
            i.fa-solid.fa-right-from-bracket
            | Logout
</template>

<script>
import { store } from "@/store";

export default {
  data() {
    return {
      STORE: store,
      STATE: store.state,
      menuOpen: false,
      isUsingExpertTitles: localStorage.getItem('useTrackTitleFilter') === 'true' ? true : false,
    };
  },
  components: {},
  created() {},
  computed: {
  },
  methods: {
    getUser() {
      return this.$auth.isAuthenticated() ? this.$auth.getPayload().username : "";
    },
    async logout() {
      await this.$auth.logout();
      await this.$auth.forcedLogout();
      store.navigate("/");
    },
    isAdmin() {
      return this.$auth.IsAdmin;
    },
    toggleExpertTitles() {
      const key = "useTrackTitleFilter";
      this.isUsingExpertTitles = localStorage.getItem(key) === 'true' ? false : true;
      localStorage.setItem(key, this.isUsingExpertTitles);
    },
  },
};
</script>


<style scoped lang="stylus">

</style>
