import router from '@/routes.js'

// Our URLs
export const URLS = {
    Site: process.env.VUE_APP_SITE_URL,
    API: process.env.VUE_APP_API_URL,
    Stream: process.env.VUE_APP_STREAM_URL,
    WS: process.env.VUE_APP_WS_URL,
    TrackImages: process.env.VUE_APP_TRACK_IMAGES_URL,
}

export const store = {
    state: {
        station: false,
        player: { station_name: "SR One", station_slug: "requests", meta: {icon: "requests"} },
        playerCurTrackBgImage: '',
        search: false,
        isAuthed: false, // Updates from main.js
        user: {
            favorites: [],
        },
    },
    stations: [],
    titleFilters: [],

    navigate(route_info) {
        router.push(route_info).catch(err => { err })
    },

    showHome() {
        this.navigate('/');
    },

    setStations(stations) {
        this.stations = stations;
    },

    setStation(requestedStation) {
        var station_info = store.stations.find((s) => s.station_slug == requestedStation);
        if (!station_info) { 
            this.showHome() 
        } else {
            this.state.station = station_info;
        }
    },

    viewStation(station_info) {
        this.state.station = station_info;
        this.navigate({ name: 'station', params: { id: station_info.station_slug } })
    },

    // Set the player to whichever current station we're watching
    setPlayerAndPlay() {
        this.state.player = this.state.station;
    },

}
