<template>
  <section>
    <!-- Station Heading -->
    <header>
      <div @click="setPlayerAndPlay()">
        <div class="station-icon">
          <img
            v-if="STATE.station.meta"
            :src="this.getStreamIcon(STATE.station.meta.icon)"
          />
          <i class="fa fa-play"></i>
        </div>
        <div>
          <div class="station-name">{{ STATE.station.station_name }}</div>
        </div>
      </div>
      <div>
        <i class="fa fa-search fa-2x"></i>
        <input
          ref="search-input"
          v-model="search_text"
          :placeholder="'Search ' + STATE.station.station_name"
          @focus="searchOpen"
        />
      </div>
    </header>

    <transition name="fade" mode="out-in">
      <Search
        :search_text="search_text"
        :parentSearchClose=searchClose
        v-if="searching"
        v-CloseClickOutside="{exclude: ['search-input'], handler: 'searchClose'}"
      />
      <Playlist v-else />
    </transition>
  </section>
</template>

<script>
import { store } from "@/store";

import Search from "./Search";
import Playlist from "./Playlist";
import { CloseClickOutside } from "@/directives.js";
import SrUtils from "@/mixins/srutils.js";

export default {
  mixins: [SrUtils],
  components: {
    Search,
    Playlist
  },
  directives: {
    CloseClickOutside
  },
  data() {
    return {
      STATE: store.state,
      searching: false,
      search_text: ""
    };
  },
  mounted() {
    // We can come to this page with a pre-loaded search
    if(store.temp_track_search) {
      this.search_text = store.temp_track_search;
      store.temp_track_search = null;
      this.searchOpen()
    }
  },
  methods: {
    searchOpen() {
      this.searching = true;
    },
    searchClose() {
      this.searching = false;
    },
    setPlayerAndPlay() {
      // Station should already be set if we are in the station view
      store.setPlayerAndPlay();
    }
  },
  events: {}
};
</script>


<style scoped lang="stylus">
@media screen and (max-width: 768px)
  .station-icon
    width 60px

section
  margin 5px
  flex-grow 1
  display flex
  flex-direction column

header
  display flex
  flex-wrap wrap
  > div
    padding 5px
    input
      width 100%
      border-radius 10px
      padding 3px 5px
      font-size 120%
      font-weight bold
      color #444
    // Station Info
    &:nth-child(1)
      display flex
      flex 1 1 30%
      align-items center
      cursor pointer
    // Search
    &:nth-child(2)
      flex 1 0 70%
      display flex
      align-items center
</style>
